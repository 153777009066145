import { Formik } from "formik";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import useFetch from "use-http";
import * as Yup from "yup";
import AppProvider from "../components/AppProvider";
import CustomLoader from "../components/CustomLoader";
import { CustomMultiSelect } from "../components/CustomMultiSelect";
import CustomPageLayout from "../components/CustomPageLayout";

const StandardBranch = () => {
  return (
    <>
      <CustomPageLayout Add={Add} Index={Index} Update={Update} View={View} />
    </>
  );
};

export interface GetStandardRoot {
  success: boolean;
  message: string;
  data: GetStandardDaum[];
}

export interface GetStandardDaum {
  _id: string;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface GetBranchRoot {
  success: boolean;
  message: string;
  data: GetBranchDaum[];
}

export interface GetBranchDaum {
  _id: string;
  name: string;
  latitude: string;
  longitude: string;
  radius: string;
  address: string;
  abbreviation: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  isFirstHalf: boolean;
  isSecondHalf: boolean;
  accessCode?: string;
  merchantId?: string;
  password?: string;
  username?: string;
  workingKey?: string;
}

export interface Root {
  success: boolean;
  message: string;
  data: Daum[];
}

export interface Daum {
  _id: string;
  standard: string;
  branch: Branch[];
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface Branch {
  _id: string;
  name: string;
  latitude: string;
  longitude: string;
  radius: string;
  address: string;
  abbreviation: string;
  isFirstHalf: boolean;
  isSecondHalf: boolean;
  username?: string;
  password?: string;
  merchantId?: string;
  accessCode?: string;
  workingKey?: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

const Index = () => {
  const [isFetching, setIsFetching] = useState(false);
  const { get, post, response } = useFetch<Root>("/standardBranch");
  const { get: getStandard, response: getStandardResponse } =
    useFetch<GetStandardRoot>("/standard");
  const { get: getBranch, response: getBranchResponse } =
    useFetch<GetBranchRoot>("/branch");

  useEffect(() => {
    (async () => await fetchData())();
  }, []);

  const fetchData = async () => {
    try {
      setIsFetching(true);
      await getStandard();
      await getBranch();
      await get();
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      console.log(err);
    }
  };

  const handleSubmit = async (values: {
    standard: string;
    branch: string[];
  }) => {
    try {
      if (values.branch.length === 0) {
        alert("please select branch");
        return;
      }

      setIsFetching(true);
      await post({
        branch: values.branch,
        standard: values.standard,
      });
      await fetchData();
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      console.log(err);
    }
  };

  if (isFetching) {
    return (
      <>
        <AppProvider>
          <CustomLoader />
        </AppProvider>
      </>
    );
  }

  return (
    <>
      <AppProvider>
        <Container fluid className="mt-2">
          <Card>
            <Card.Header>MANAGE STANDARD BRANCH</Card.Header>
            <Card.Body>
              <Formik
                initialValues={{
                  standard: "",
                  branch: [],
                }}
                validationSchema={Yup.object().shape({
                  standard: Yup.string().required(),
                })}
                onSubmit={handleSubmit}
              >
                {({
                  errors,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <>
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row>
                        <Col>
                          <Form.Group controlId="validationFormik02">
                            <Form.Label>Standard</Form.Label>
                            <Form.Select
                              size="sm"
                              aria-label="Default select example"
                              name="standard"
                              onChange={handleChange}
                              value={values.standard}
                              isInvalid={
                                !!touched.standard && !!errors.standard
                              }
                            >
                              <option value={""}>select standard</option>

                              {getStandardResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>

                        <Col>
                          <CustomMultiSelect
                            data={
                              getBranchResponse.data?.data
                                ?.filter((item) => item.isActive)
                                ?.sort((a, b) => {
                                  if (
                                    a.name.toLowerCase() < b.name.toLowerCase()
                                  )
                                    return -1;
                                  if (
                                    a.name.toLowerCase() > b.name.toLowerCase()
                                  )
                                    return 1;
                                  return 0;
                                })
                                .map((x) => {
                                  return { label: x.name, value: x._id };
                                }) || []
                            }
                            name="branch"
                            setFieldValue={setFieldValue}
                            isRequired
                            label="branch"
                            value={values.branch}
                            handleChange={handleChange}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit" size="sm">
                            SUBMIT
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </>
                )}
              </Formik>
            </Card.Body>
          </Card>

          <Table bordered className="mt-2">
            <thead>
              <tr>
                <th>SR. NO.</th>
                <th>Standard</th>
                <th>Branch</th>
              </tr>
            </thead>
            <tbody>
              {getStandardResponse.data?.data?.map((standard, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{standard.name}</td>
                  <td>
                    {response.data?.data
                      ?.find((item) => item.standard === standard._id)
                      ?.branch?.map((item) => (
                        <p>{item?.name}</p>
                      ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      </AppProvider>
    </>
  );
};

const Add = () => {
  return <></>;
};

const Update = () => {
  return <></>;
};

const View = () => {
  return <></>;
};

export default StandardBranch;
