import { To } from "react-router-dom";

interface INavbarLink {
  _id: string;
  name: string;
  link: To;
  children: INavbarLink[];
}

export const navbarLinks: INavbarLink[] = [
  {
    _id: "0",
    name: "User",
    link: "/user",
    children: [
      {
        _id: "00",
        name: "Teacher",
        link: "/teacher",
        children: [],
      },
      {
        _id: "01",

        name: "Student",
        link: "/student",
        children: [],
      },
      {
        _id: "02",

        name: "Parent",
        link: "/parent",
        children: [],
      },
      {
        _id: "03",

        name: "Branch Admin",
        link: "/branchAdmin",
        children: [],
      },
    ],
  },

  {
    _id: "1",

    name: "Master",
    link: "/master",
    children: [
      {
        _id: "11",

        name: "Branch",
        link: "/branch",
        children: [],
      },
      // {
      //   _id: "12",

      //   name: "Designation",
      //   link: "/designation",
      //   children: [],
      // },
      // {
      //   _id: "13",

      //   name: "Department",
      //   link: "/department",
      //   children: [],
      // },
      {
        _id: "14",

        name: "Subject",
        link: "/subject",
        children: [],
      },
      {
        _id: "15",

        name: "Standard",
        link: "/standard",
        children: [],
      },
      // {
      //   _id: "16",

      //   name: "Stream",
      //   link: "/stream",
      //   children: [],
      // },
      // {
      //   _id: "17",

      //   name: "Year",
      //   link: "/year",
      //   children: [],
      // },
      // {
      //   _id: "18",

      //   name: "Board",
      //   link: "/board",
      //   children: [],
      // },
      // {
      //   _id: "19",

      //   name: "School",
      //   link: "/school",
      //   children: [],
      // },
      {
        _id: "110",

        name: "Relation",
        link: "/relation",
        children: [],
      },
      {
        _id: "111",

        name: "Gender",
        link: "/gender",
        children: [],
      },
      {
        _id: "112",

        name: "Academic Year",
        link: "/academicyear",
        children: [],
      },
      // {
      //   _id: "113",

      //   name: "Batch",
      //   link: "/batch",
      //   children: [],
      // },
      // {
      //   _id: "114",

      //   name: "Shift",
      //   link: "/shift",
      //   children: [],
      // },
      {
        _id: "115",

        name: "Material Type",
        link: "/studyMaterialType",
        children: [],
      },
      {
        _id: "116",

        name: "Day",
        link: "/day",
        children: [],
      },
    ],
  },

  {
    _id: "2",

    name: "Setup",
    link: "/setup",
    children: [
      {
        _id: "20",

        name: "Lecture",
        link: "/lecture",
        children: [],
      },
      {
        _id: "21",

        name: "Test",
        link: "/test",
        children: [],
      },
      {
        _id: "22",

        name: "Backup Session",
        link: "/backupSession",
        children: [],
      },
      {
        _id: "23",

        name: "Manage Teacher",
        link: "/manageTeacher",
        children: [],
      },
      {
        _id: "24",

        name: "Manage Branch",
        link: "/manageBranch",
        children: [],
      },
      {
        _id: "25",

        name: "Branch Distance Time Matrix",
        link: "/branchDistanceTimeMatrix",
        children: [],
      },
      {
        _id: "26",

        name: "Fees",
        link: "/fees",
        children: [],
      },
      {
        _id: "27",

        name: "Fees Discount",
        link: "/feesDiscount",
        children: [],
      },
      // {
      //   _id: "28",

      //   name: "Manage Branch Centre Concept",
      //   link: "/setup/manageBranchCentreConcept",
      //   children: [],
      // },
      {
        _id: "29",

        name: "Standard Branch",
        link: "/setup/standardBranch",
        children: [],
      },
    ],
  },

  {
    _id: "3",

    name: "Study",
    link: "/study",
    children: [
      {
        _id: "30",

        name: "Material",
        link: "/studyMaterial",
        children: [],
      },
    ],
  },

  {
    _id: "4",

    name: "Appointment",
    link: "/appointment",
    children: [
      {
        _id: "40",

        name: "Time Slot",
        link: "/timeSlot",
        children: [],
      },
      {
        _id: "41",

        name: "Type",
        link: "/appointmentType",
        children: [],
      },
      {
        _id: "42",

        name: "Manage",
        link: "/appointment",
        children: [],
      },
    ],
  },

  {
    _id: "5",
    name: "Report",
    link: "/report",
    children: [
      {
        _id: "50",
        name: "Attendance",
        link: "/report/attendance",
        children: [],
      },
      {
        _id: "51",
        name: "Payment",
        link: "/report/payment",
        children: [],
      },
      {
        _id: "52",
        name: "App User Activity",
        link: "/report/appUserActivity",
        children: [],
      },
    ],
  },

  {
    _id: "6",
    name: "Other",
    link: "/other",
    children: [
      {
        _id: "60",
        name: "Update Standard",
        link: "/other/updateStandard",
        children: [],
      },
      {
        _id: "61",
        name: "Add Payment",
        link: "/other/addPayment",
        children: [],
      },
      {
        _id: "62",
        name: "Update Academic Year",
        link: "/other/updateAcademicYear",
        children: [],
      },
      {
        _id: "63",
        name: "Sync Fees with Students",
        link: "/other/syncFeesWithStudents",
        children: [],
      },
      {
        _id: "64",
        name: "Update Branch",
        link: "/other/updateBranch",
        children: [],
      },
      // {
      //   _id: "65",
      //   name: "Demo",
      //   link: "/other/demo",
      //   children: [],
      // },
    ],
  },
];
