const TermsAndConditions = () => {
  return (
    <div
      style={{
        margin: 10,
        padding: 10,
      }}
    >
      <h1>Terms & Conditions</h1>
      <p>
        <strong>Effective Date:</strong> 01-01-2025 (DD-MM-YYYY)
      </p>

      <p>
        Welcome to <strong>SCPL CONNECT</strong> ("the App"). By using this app,
        you agree to comply with these Terms & Conditions. If you do not agree,
        please do not use the app.
      </p>

      <h2>1. Use of the App</h2>
      <p>
        The App is designed for educational purposes, enabling students and
        parents to access lectures, tests, study material, backup sessions, test
        results, appointments with teachers, fee payments, and reports.
      </p>
      <p>
        You must use the app only for lawful purposes and in accordance with
        these Terms & Conditions.
      </p>
      <p>
        The app is intended for users aged 16 and above, including students in
        higher secondary (11th and 12th) and college-level education, as well as
        their parents.
      </p>

      <h2>2. Account and Login Credentials</h2>
      <p>
        Access to the app requires a username and password, provided by your
        educational institute.
      </p>
      <p>
        Parents and students are responsible for maintaining the confidentiality
        of their login credentials.
      </p>
      <p>
        If you believe your account has been compromised, contact your
        educational institute immediately.
      </p>

      <h2>3. Calendar Permissions</h2>
      <p>
        The app requests calendar permissions to sync lectures and tests with
        your Google Calendar.
      </p>
      <p>
        Granting this permission is optional and can be revoked at any time
        through your device settings.
      </p>
      <p>
        The calendar sync feature does not store or collect your calendar data
        on our servers.
      </p>

      <h2>4. Payments</h2>
      <p>
        The app includes a fee payment feature, which uses a third-party payment
        gateway (<strong>[Insert Payment Gateway Name]</strong>).
      </p>
      <p>
        By using the payment feature, you agree to comply with the terms of the
        payment gateway.
      </p>
      <p>
        The payment gateway may collect personal and payment information
        necessary to process transactions. Please refer to their privacy policy
        for more details:{" "}
        <a href="[Insert Payment Gateway Privacy Policy Link]" target="_blank">
          Payment Gateway Privacy Policy
        </a>
        .
      </p>

      <h2>5. Data Security</h2>
      <p>
        We implement security measures to protect your data stored on our
        servers.
      </p>
      <p>
        The app does not collect or share any user data with third parties,
        except as required for processing payments.
      </p>

      <h2>6. User Responsibilities</h2>
      <p>
        You agree not to misuse the app, including attempting to gain
        unauthorized access to the app or its systems.
      </p>
      <p>
        Students and parents are responsible for any actions taken using their
        accounts.
      </p>
      <p>Any misuse of the app may result in the termination of your access.</p>

      <h2>7. Limitations of Liability</h2>
      <p>
        We strive to ensure the app functions smoothly, but we do not guarantee
        uninterrupted or error-free performance.
      </p>
      <p>
        SCPL CONNECT is not liable for any direct, indirect, or incidental
        damages arising from your use of the app.
      </p>
      <p>
        The app relies on external systems (e.g., payment gateways, calendar
        sync), and we are not responsible for their performance.
      </p>

      <h2>8. Termination</h2>
      <p>
        We reserve the right to terminate or restrict your access to the app at
        any time without prior notice if you violate these Terms & Conditions.
      </p>
      <p>
        Users can request account-related actions, including deletion, by
        contacting us via email.
      </p>

      <h2>9. Changes to Terms & Conditions</h2>
      <p>
        We reserve the right to update these Terms & Conditions at any time.
        Updates will be effective upon being published in the app or on our
        website. Continued use of the app constitutes your acceptance of the
        updated terms.
      </p>

      {/* <h2>10. Governing Law</h2>
      <p>
        These Terms & Conditions are governed by the laws of{" "}
        <strong>[Insert Jurisdiction]</strong>. Any disputes arising from these
        terms will be subject to the exclusive jurisdiction of the courts of{" "}
        <strong>[Insert Jurisdiction]</strong>.
      </p> */}

      <h2>10. Contact Us</h2>
      <p>
        If you have any questions about these Terms & Conditions or need
        assistance, you can contact us at:
      </p>
      <p>Contact:- 98980 93067 / 90990 28940</p>
    </div>
  );
};

export default TermsAndConditions;
