import { Card, Container } from "react-bootstrap";
import { ScaleLoader } from "react-spinners";

const CustomLoader = () => {
  return (
    <Container fluid>
      <Card className="mt-3">
        <Card.Body className="d-flex justify-content-center align-items-center">
          <ScaleLoader />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CustomLoader;
