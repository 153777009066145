import { Formik } from "formik";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import Swal from "sweetalert2";
import useFetch from "use-http";
import * as Yup from "yup";
import AppProvider from "../components/AppProvider";
import CustomLoader from "../components/CustomLoader";
import { CustomMultiSelect } from "../components/CustomMultiSelect";
import { GetAllDesignationAPIRoot } from "../utils/types";
export interface Root {
  success: boolean;
  message: string;
  data: Daum[];
}

export interface Daum {
  _id: string;
  branch: Branch;
  standard: Standard;
  isConcept: boolean;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface Branch {
  _id: string;
  name: string;
  latitude: string;
  longitude: string;
  radius: string;
  address: string;
  abbreviation: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  isFirstHalf: boolean;
  isSecondHalf: boolean;
  accessCode: string;
  merchantId: string;
  password: string;
  username: string;
  workingKey: string;
}

export interface Standard {
  _id: string;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

const ManageBranchCentreConcept = () => {
  const { get: getBranch, response: branchResponse } =
    useFetch<GetAllDesignationAPIRoot>("/branch");
  const { get: getStandard, response: standardResponse } =
    useFetch<GetAllDesignationAPIRoot>("/standard");
  const { get, post, del, response } = useFetch<Root>(
    "/manageBranchCentreConcept"
  );

  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    (async () => await fetchData())();
  }, []);

  const fetchData = async () => {
    try {
      setIsFetching(true);

      await getBranch();
      await getStandard();
      await get().then((res) => console.log(res));

      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);

      console.log(err);
    }
  };

  const handleSubmit = async (values: {
    branch: string;
    standard: string[];
  }) => {
    try {
      if (values.standard.length) {
        setIsFetching(true);

        await Promise.all(
          values.standard.map(async (item) => {
            await post({
              branch: values.branch,
              standard: item,
              isConcept: true,
            });
          })
        );

        await fetchData();

        setIsFetching(false);
      } else {
        alert("Please select standard");
        return;
      }
    } catch (err) {
      setIsFetching(false);

      console.log(err);
    }
  };

  const handleDelete = async (_id: string) => {
    try {
      setIsFetching(true);

      await del(`${_id}`);
      await fetchData();

      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      console.log(err);
    }
  };

  if (isFetching) {
    return (
      <AppProvider>
        <CustomLoader />
      </AppProvider>
    );
  }

  return (
    <>
      <AppProvider>
        <Container fluid>
          <Card className="mt-3">
            <Card.Header>
              <h5>Manage Branch Centre Concept</h5>
            </Card.Header>

            <Card.Body>
              <Formik
                initialValues={{
                  branch: "",
                  standard: [],
                }}
                onSubmit={handleSubmit}
                validationSchema={Yup.object().shape({
                  branch: Yup.string().required(),
                })}
              >
                {({
                  errors,
                  handleSubmit,
                  handleChange,
                  isSubmitting,
                  setFieldValue,
                  values,
                  touched,
                }) => (
                  <>
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row>
                        <Col>
                          <Form.Group controlId="validationFormik02">
                            <Form.Label>
                              Branch
                              <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Select
                              size="sm"
                              aria-label="Default select example"
                              name="branch"
                              onChange={handleChange}
                              value={values.branch}
                              isInvalid={!!touched.branch && !!errors.branch}
                            >
                              <option value={""}>Select</option>

                              {branchResponse &&
                                branchResponse.data &&
                                branchResponse.data?.data &&
                                branchResponse.data?.data.filter(
                                  (x) => x.isActive
                                ) &&
                                branchResponse.data?.data.map((item) => (
                                  <option value={item._id}>{item.name}</option>
                                ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>

                        <Col>
                          <CustomMultiSelect
                            data={
                              standardResponse.data?.data
                                ?.filter((item) => item.isActive)
                                ?.sort((a, b) => {
                                  if (
                                    a.name.toLowerCase() < b.name.toLowerCase()
                                  )
                                    return -1;
                                  if (
                                    a.name.toLowerCase() > b.name.toLowerCase()
                                  )
                                    return 1;
                                  return 0;
                                })
                                .map((x) => {
                                  return { label: x.name, value: x._id };
                                }) || []
                            }
                            name="standard"
                            setFieldValue={setFieldValue}
                            isRequired
                            label="Standard"
                            value={values.standard}
                            handleChange={handleChange}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit" size="sm">
                            SUBMIT
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </>
                )}
              </Formik>
            </Card.Body>
          </Card>

          <Card className="mt-3">
            <Card.Header>
              <h5>CONCEPT DATA</h5>
            </Card.Header>

            <Card.Body>
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th>Index</th>
                    <th>Branch</th>
                    <th>Standard</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {response.data?.data?.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.branch?.name}</td>
                      <td>{item?.standard.name}</td>
                      <td>
                        <Button
                          size="sm"
                          variant="danger"
                          onClick={() => {
                            Swal.fire({
                              title: "Are you sure?",
                              text: "You won't be able to revert this!",
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonColor: "#3085d6",
                              cancelButtonColor: "#d33",
                              confirmButtonText: "Yes, delete it!",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                handleDelete(item._id);
                              }
                            });
                          }}
                        >
                          DELETE
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Container>
      </AppProvider>
    </>
  );
};

export default ManageBranchCentreConcept;
