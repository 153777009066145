import { Formik } from "formik";
import _ from "lodash";
import { asyncMap } from "modern-async";
import moment from "moment";
import queryString from "query-string";
import { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import toast from "react-hot-toast";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useFetch } from "use-http";
import { read, utils, writeFile } from "xlsx";
import * as Yup from "yup";
import AppProvider from "../components/AppProvider";
import CustomLoader from "../components/CustomLoader";
import CustomPageLayout from "../components/CustomPageLayout";
import { useAppSelector } from "../store/hooks";
import {
  AddDesignationRoot,
  GetAllDesignationAPIRoot,
  GetAllStudentRoot,
} from "../utils/types";

const Student = () => {
  return (
    <CustomPageLayout Add={Add} Index={Index} Update={Update} View={View} />
  );
};

const Update = () => {
  return <></>;
};

const View = () => {
  return <></>;
};

interface excelInputType {
  NAME: string | null;
  USERNAME: string | null;
  CONTACT: string | null;
  FATHER: string | null;
  MOTHER: string | null;
}

const NAME = 0;
const USERNAME = 1;
const CONTACT = 2;
const FATHER = 3;
const MOTHER = 4;

export interface Root {
  success: boolean;
  message: string;
  data: Daum[];
}

export interface Daum {
  _id: string;
  name: string;
  username: string;
  contact: string;
  fatherContact: string;
  motherContact: string;
  standard: Standard;
  branch: Branch;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  academicYear: AcademicYear;
  firstInstalment: string;
  lumpsum: string;
  secondInstalment: string;
  token: string;
  address?: string;
  batch?: Batch;
  board?: Board;
  dateOfBirth?: string;
  email?: string;
  gender?: Gender;
  school?: School;
}

export interface Standard {
  _id: string;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface Branch {
  _id: string;
  name: string;
  latitude: string;
  longitude: string;
  radius: string;
  address: string;
  abbreviation: string;
  isFirstHalf: boolean;
  isSecondHalf: boolean;
  username: string;
  password: string;
  merchantId: string;
  accessCode: string;
  workingKey: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface AcademicYear {
  _id: string;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface Batch {
  _id: string;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface Board {
  _id: string;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface Gender {
  _id: string;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface School {
  _id: string;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface AcademicYearRoot {
  success: boolean;
  message: string;
  data: AcademicYearDaum[];
}

export interface AcademicYearDaum {
  _id: string;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

const Index = () => {
  const [data, setData] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const branchId = useAppSelector((state) => state.branchReducer._id);

  const [excelData, setExeclData] = useState<excelInputType[]>();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { post } = useFetch<Root>("/student");
  const { get, response: academicYear } =
    useFetch<AcademicYearRoot>("/educationYear");
  const { get: getStandard, response: standardResponse } =
    useFetch<GetAllDesignationAPIRoot>("/standard");
  const { get: getBatch, response: batchResponse } =
    useFetch<GetAllDesignationAPIRoot>("/batch");
  const { get: getBranch, response: branchResponse } =
    useFetch<GetAllDesignationAPIRoot>("/branch");
  const {
    post: studentDataByBranch,
    delete: deleteDesignation,
    response,
    loading,
  } = useFetch<GetAllStudentRoot>("/student");

  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    (async () => {
      if (branchId) await getData();
    })();
  }, [branchId]);

  const getData = async () => {
    try {
      setRefreshing(true);

      await get();
      await getStandard();
      await getBatch();
      await getBranch();

      await studentDataByBranch("/byBranch", {
        branchId: branchId,
      });
      setRefreshing(false);

      setKey(Math.random());
    } catch (err) {
      setRefreshing(false);
      console.log(err);
    }
  };

  const handleDelete = async (_id: string) => {
    setRefreshing(true);

    await deleteDesignation(`${_id}`).then((res) => {
      if (res.success) {
        Swal.fire({
          title: "Deleted!",
          text: "Your data has been deleted.",
          icon: "success",
        });
      }
    });
    await getData();

    setRefreshing(false);
  };
  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };

  const handleFileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const reader = new FileReader();

      reader.onload = (e) => {
        //@ts-ignore
        const data = e.target.result;
        const workbook = read(data, { type: "array" });
        var firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        var result = utils.sheet_to_json(firstSheet, { header: 1 });

        const arr: excelInputType[] = [];

        result.map((x) => {
          Array.isArray(x) &&
            arr.push({
              NAME: x[NAME],
              USERNAME: x[USERNAME],
              CONTACT: x[CONTACT],
              FATHER: x[FATHER],
              MOTHER: x[MOTHER],
            });
        });

        const filterdData = arr.filter(
          (x) =>
            !(
              _.isEmpty(x.NAME) &&
              _.isEmpty(x.USERNAME) &&
              _.isEmpty(x.CONTACT) &&
              _.isEmpty(x.FATHER) &&
              _.isEmpty(x.MOTHER)
            )
        );

        setExeclData(filterdData.slice(1));
      };
      // @ts-ignore
      reader.readAsArrayBuffer(e.target.files[0]);

      toast.success("file readed successfully");
    } catch (err) {
      toast.error("Something went wrong while file upload");
    }
  };

  const handleImport = async () => {
    try {
      handleClose();

      setRefreshing(true);

      if (excelData && excelData.length !== 0) {
        await asyncMap(excelData, async (x) => {
          await post("/test", x)
            .then((res) => {})
            .catch((err) => console.log(err));
        });

        setRefreshing(false);

        toast.success("Students added");

        await getData();
      }
    } catch (err) {
      setRefreshing(false);
      toast.error("something went wrong");
    }
  };

  const handleExport = async () => {
    const arrData: string[][] = [];
    const headingArray: string[] = [
      "NAME",
      "USERNAME",
      "CONTACT",
      "FATHER",
      "MOTHER",
    ];

    arrData.push(headingArray);

    response.data?.data.map((x) => {
      arrData.push([
        x.name || "-",
        x.username || "-",
        x.contact || "-",
        x.fatherContact || "-",
        x.motherContact || "-",
      ]);
    });

    const workbook = utils.book_new();
    const worksheet = utils.aoa_to_sheet(arrData);
    utils.book_append_sheet(workbook, worksheet);

    writeFile(
      workbook,
      "STUDENT-DATA-" + moment().format("DD-MM-YYYY-HH-MM-A") + ".xlsx"
    );
  };

  const handleSubmit = async (values: {
    branch: string;
    standard: string;
    academicYear: string;
  }) => {
    try {
      if (excelData && excelData.length !== 0) {
        handleClose();

        setRefreshing(true);

        await asyncMap(excelData, async (x) => {
          await post("/test", {
            ...x,
            branch: values.branch,
            standard: values.standard,
            academicYear: values.academicYear,
          });
        });

        setRefreshing(false);

        toast.success("Students added");

        await getData();
      } else {
        toast.error("Select Excel");
      }

      setRefreshing(false);
    } catch (err) {
      setRefreshing(false);
      toast.error("something went wrong");
    }
  };

  const currentData = response?.data?.data
    ?.sort((a, b) => {
      if (a.username < b.username) {
        return -1;
      } else {
        if (a.name > b.name) {
          return 1;
        } else return 0;
      }
    })
    .slice(
      currentPage * itemsPerPage,
      (currentPage + 1) * itemsPerPage
    ) as Daum[];

  if (refreshing) {
    return (
      <>
        <AppProvider>
          <CustomLoader />
        </AppProvider>
      </>
    );
  }

  if (!branchId) {
    return (
      <>
        <AppProvider>
          <Container fluid>
            <Card className="mt-2">
              <Card.Body className="justify-content-center">
                PLEASE SELECT BRANCH
              </Card.Body>
            </Card>
          </Container>
        </AppProvider>
      </>
    );
  }

  if (response.data?.data.length === 0) {
    return (
      <>
        <AppProvider>
          <Container fluid>
            <Card className="mt-3">
              <Card.Body className="d-flex justify-content-between align-items-center">
                <div>NO DATA AVAILABLE</div>

                <div className="d-flex gap-1">
                  <Button variant="primary" size="sm" onClick={handleShow}>
                    IMPORT
                  </Button>
                  <Button variant="success" size="sm" onClick={handleExport}>
                    EXPORT
                  </Button>
                </div>

                <Modal show={show} onHide={handleClose} size="xl">
                  <Modal.Header closeButton>
                    <Modal.Title>Import Excel</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Formik
                      initialValues={{
                        academicYear: "",
                        standard: "",
                        branch: "",
                      }}
                      onSubmit={handleSubmit}
                      validationSchema={Yup.object().shape({
                        academicYear: Yup.string().required(),
                        standard: Yup.string().required(),
                        branch: Yup.string().required(),
                      })}
                      key={key}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                      }) => {
                        return (
                          <div>
                            <Row>
                              <Col>
                                <Form.Group controlId="validationFormik02">
                                  <Form.Label>Branch</Form.Label>
                                  <Form.Select
                                    size="sm"
                                    aria-label="Default select example"
                                    name="branch"
                                    onChange={handleChange}
                                    value={values.branch}
                                    isInvalid={
                                      !!touched.branch && !!errors.branch
                                    }
                                  >
                                    <option value={""}>Select</option>

                                    {branchResponse &&
                                      branchResponse.data &&
                                      branchResponse.data?.data &&
                                      branchResponse.data?.data.map((item) => (
                                        <option value={item._id}>
                                          {item.name}
                                        </option>
                                      ))}
                                  </Form.Select>
                                </Form.Group>
                              </Col>

                              <Col>
                                <Form.Group controlId="validationFormik02">
                                  <Form.Label>Standard</Form.Label>
                                  <Form.Select
                                    size="sm"
                                    aria-label="Default select example"
                                    name="standard"
                                    onChange={handleChange}
                                    value={values.standard}
                                    isInvalid={
                                      !!touched.standard && !!errors.standard
                                    }
                                  >
                                    <option value={""}>Select</option>

                                    {standardResponse &&
                                      standardResponse.data &&
                                      standardResponse.data?.data &&
                                      standardResponse.data?.data.map(
                                        (item) => (
                                          <option value={item._id}>
                                            {item.name}
                                          </option>
                                        )
                                      )}
                                  </Form.Select>
                                </Form.Group>
                              </Col>

                              <Col>
                                <Form.Group controlId="validationFormik02">
                                  <Form.Label>Academic Year</Form.Label>
                                  <Form.Select
                                    size="sm"
                                    aria-label="Default select example"
                                    name="academicYear"
                                    onChange={handleChange}
                                    value={values.academicYear}
                                    isInvalid={
                                      !!touched.academicYear &&
                                      !!errors.academicYear
                                    }
                                  >
                                    <option value={""}>Select</option>

                                    {academicYear &&
                                      academicYear.data &&
                                      academicYear.data?.data &&
                                      academicYear.data?.data.map((item) => (
                                        <option value={item._id}>
                                          {item.name}
                                        </option>
                                      ))}
                                  </Form.Select>
                                </Form.Group>
                              </Col>

                              <Col>
                                <Form.Group
                                  controlId="formFile"
                                  className="mb-3"
                                >
                                  <Form.Label>Select excel file</Form.Label>
                                  <Form.Control
                                    type="file"
                                    size="sm"
                                    accept=".xlsx, .xls, .csv"
                                    onChange={handleFileSelect}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>

                            <Row>
                              <Col
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  gap: "1rem",
                                }}
                              >
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={() => handleSubmit()}
                                >
                                  Save Changes
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        );
                      }}
                    </Formik>
                  </Modal.Body>
                </Modal>
              </Card.Body>
            </Card>
          </Container>
        </AppProvider>
      </>
    );
  }

  return (
    <>
      <AppProvider>
        <Modal show={show} onHide={handleClose} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Import Excel</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                academicYear: "",
                standard: "",
                branch: "",
              }}
              onSubmit={handleSubmit}
              validationSchema={Yup.object().shape({
                academicYear: Yup.string().required(),
                standard: Yup.string().required(),
                branch: Yup.string().required(),
              })}
              key={key}
            >
              {({ handleSubmit, handleChange, values, touched, errors }) => {
                return (
                  <div>
                    <Row>
                      <Col>
                        <Form.Group controlId="validationFormik02">
                          <Form.Label>Branch</Form.Label>
                          <Form.Select
                            size="sm"
                            aria-label="Default select example"
                            name="branch"
                            onChange={handleChange}
                            value={values.branch}
                            isInvalid={!!touched.branch && !!errors.branch}
                          >
                            <option value={""}>Select</option>

                            {branchResponse &&
                              branchResponse.data &&
                              branchResponse.data?.data &&
                              branchResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group controlId="validationFormik02">
                          <Form.Label>Standard</Form.Label>
                          <Form.Select
                            size="sm"
                            aria-label="Default select example"
                            name="standard"
                            onChange={handleChange}
                            value={values.standard}
                            isInvalid={!!touched.standard && !!errors.standard}
                          >
                            <option value={""}>Select</option>

                            {standardResponse &&
                              standardResponse.data &&
                              standardResponse.data?.data &&
                              standardResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group controlId="validationFormik02">
                          <Form.Label>Academic Year</Form.Label>
                          <Form.Select
                            size="sm"
                            aria-label="Default select example"
                            name="academicYear"
                            onChange={handleChange}
                            value={values.academicYear}
                            isInvalid={
                              !!touched.academicYear && !!errors.academicYear
                            }
                          >
                            <option value={""}>Select</option>

                            {academicYear &&
                              academicYear.data &&
                              academicYear.data?.data &&
                              academicYear.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group controlId="formFile" className="mb-3">
                          <Form.Label>Select excel file</Form.Label>
                          <Form.Control
                            type="file"
                            size="sm"
                            accept=".xlsx, .xls, .csv"
                            onChange={handleFileSelect}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: "1rem",
                        }}
                      >
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                        <Button
                          variant="primary"
                          onClick={() => handleSubmit()}
                        >
                          Save Changes
                        </Button>
                      </Col>
                    </Row>
                  </div>
                );
              }}
            </Formik>
          </Modal.Body>
        </Modal>

        <Container
          fluid
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Card>
            <Card.Header>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: "3rem",
                  alignItems: "center",
                }}
              >
                <div>
                  <h5>Student</h5>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "1rem",
                  }}
                >
                  {true && (
                    <Button variant="secondary" size="sm" onClick={handleShow}>
                      IMPORT
                    </Button>
                  )}

                  {true && (
                    <Button variant="success" size="sm" onClick={handleExport}>
                      EXPORT
                    </Button>
                  )}

                  {false && (
                    <Button
                      size="sm"
                      onClick={() => navigate("/student?action=add")}
                    >
                      ADD
                    </Button>
                  )}
                </div>
              </div>
            </Card.Header>

            <Card.Body>
              <Table responsive bordered hover>
                <thead>
                  <tr>
                    <th>SR. NO.</th>
                    <th>Name</th>
                    <th>Username</th>
                    <th>Branch</th>
                    <th>Standard</th>
                    <th>Academic Year</th>
                    <th>Token</th>
                    <th>Lumpsum</th>
                    <th>First Instalment</th>
                    <th>Second Instalment</th>
                    <th>Contact</th>
                    <th>Father</th>
                    <th>Mother</th>
                    <th>Is Active</th>
                    <th
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentData &&
                    currentData.map((item, index: number) => (
                      <tr key={item._id}>
                        <td>{index + 1 + currentPage * itemsPerPage}</td>
                        <td>{item?.name || ""}</td>
                        <td>{item?.username || ""}</td>
                        <td>{item?.branch?.name || ""}</td>
                        <td>{item?.standard?.name || ""}</td>
                        <td>{item?.academicYear?.name || ""}</td>
                        <td>{item?.token || "0"}</td>
                        <td>{item?.lumpsum || "0"}</td>
                        <td>{item?.firstInstalment || "0"}</td>
                        <td>{item?.secondInstalment || "0"}</td>
                        <td>{item?.contact || ""}</td>
                        <td>{item?.fatherContact || ""}</td>
                        <td>{item?.motherContact || ""}</td>
                        <td>{item.isActive ? "Yes" : "No"}</td>

                        <td
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "1rem",
                            }}
                          >
                            {false && (
                              <Button
                                size={"sm"}
                                onClick={() =>
                                  navigate(
                                    `/student?action=view&&id=${item._id}`
                                  )
                                }
                                variant="success"
                              >
                                View
                              </Button>
                            )}
                            {false && (
                              <Button
                                size="sm"
                                onClick={() =>
                                  navigate(
                                    `/student?action=update&&id=${item._id}`
                                  )
                                }
                              >
                                UPDATE
                              </Button>
                            )}
                            <Button
                              size={"sm"}
                              variant="danger"
                              onClick={() => {
                                Swal.fire({
                                  title: "Are you sure?",
                                  text: "You won't be able to revert this!",
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonColor: "#3085d6",
                                  cancelButtonColor: "#d33",
                                  confirmButtonText: "Yes, delete it!",
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    handleDelete(item._id);
                                  }
                                });
                              }}
                            >
                              DELETE
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col>
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={Math.ceil(
                      (response.data?.data.length || 0) / itemsPerPage
                    )}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Container>
      </AppProvider>
    </>
  );
};

const Add = () => {
  const navigate = useNavigate();
  const { post } = useFetch<AddDesignationRoot>("/student");
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const [refreshing, setRefreshing] = useState(false);

  const handleSubmit = async (values: {
    name: string;
    username: string;
    password: string;
    contact: string;
    fatherContact: string;
    motherContact: string;
    isActive: boolean;
  }) => {
    try {
      setRefreshing(true);

      await post(values).then((res) => {
        if (res.success) {
          navigate(-1);
        } else {
          setRefreshing(false);
          alert(res.message);
        }
      });

      setRefreshing(false);
    } catch (err) {
      setRefreshing(false);

      alert(err);
    }
  };

  if (refreshing) {
    <div>
      <AppProvider>
        <Spinner color="black" />
      </AppProvider>
    </div>;
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Add Student</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    username: Yup.string().required(),
                    password: Yup.string().required(),
                    name: Yup.string().required(),
                    contact: Yup.string(),
                    fatherContact: Yup.string(),
                    motherContact: Yup.string(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={{
                    name: "",
                    username: "",
                    contact: "",
                    fatherContact: "",
                    motherContact: "",
                    password: "",
                    isActive: true,
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Name <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Username <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="username"
                            value={values.username}
                            onChange={handleChange}
                            isValid={touched.username && !errors.username}
                            isInvalid={!!errors.username}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.username}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Password <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            isValid={touched.password && !errors.password}
                            isInvalid={!!errors.password}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Contact <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="contact"
                            value={values.contact}
                            onChange={handleChange}
                            isValid={touched.contact && !errors.contact}
                            isInvalid={!!errors.contact}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.contact}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Father Contact{" "}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="fatherContact"
                            value={values.fatherContact}
                            onChange={handleChange}
                            isValid={
                              touched.fatherContact && !errors.fatherContact
                            }
                            isInvalid={!!errors.fatherContact}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.fatherContact}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Mother Contact{" "}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="motherContact"
                            value={values.motherContact}
                            onChange={handleChange}
                            isValid={
                              touched.motherContact && !errors.motherContact
                            }
                            isInvalid={!!errors.motherContact}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.motherContact}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

export default Student;
