const RefundPolicy = () => {
  return (
    <div
      style={{
        margin: 10,
        padding: 10,
      }}
    >
      <h1>
        Admission Cancellation & Refund Policy – Soham Classes Private Limited
      </h1>

      <ol>
        <li>Course Enrollment Cancellation</li>
        <ul>
          <li>
            Students may cancel their course enrollment within 07 days of
            registration for a full refund.
          </li>
          <li>No refunds will be issued once the course has started.</li>
        </ul>

        <li>Refund Process</li>
        <ul>
          <li>
            Refunds (if applicable) will be processed within 07 days after
            approval of management.
          </li>
          <li>
            Payments will be refunded through the original payment method.
          </li>
        </ul>

        <li>Exceptions</li>
        <ul>
          <li>
            Medical emergencies or other unforeseen situations will be
            considered on a case-by-case basis.
          </li>
          <li>Documentation may be required to process exceptions.</li>
        </ul>

        <li>Contact for Cancellations</li>
        <ul>
          <li>
            For any cancellation requests or queries, please contact us at
            respective center.
          </li>
          <li>Contact:- 98980 93067 / 90990 28940</li>
        </ul>
      </ol>
    </div>
  );
};

export default RefundPolicy;
