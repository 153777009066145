import { useEffect, useState } from "react";
import { Container, Dropdown, Form, Navbar } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFetch } from "use-http";
import LOGO from "../assets/logo-removebg-preview.png";
import { setAuth } from "../store/authReducer";
import { setBranch } from "../store/branchReducer";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { GetAllDesignationAPIRoot } from "../utils/types";

const CustomHeader = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const _id = useAppSelector((state) => state.branchReducer._id);
  const userName = useAppSelector((state) => state.authReducer.name);
  const [key, setKey] = useState(Math.random());

  const { get, response, loading, error } =
    useFetch<GetAllDesignationAPIRoot>("/branch");

  useEffect(() => {
    const fetchData = async () => {
      await get()
        .then((res) => {
          if (res) {
            if (res.success) setKey(Math.random());
          }
        })
        .catch((err) => console.log(err));
    };

    fetchData();
  }, []);

  return (
    <div>
      <Navbar expand="lg" style={{ backgroundColor: "#008080", color: "#fff" }}>
        <Container>
          <Navbar.Brand>
            <Link
              to={"/dashboard"}
              style={{
                textDecoration: "none",
                color: "white",
                fontSize: 28,
                fontWeight: "bold",
              }}
            >
              <img src={LOGO} style={{ height: 50 }} alt="SCPL" />
            </Link>
          </Navbar.Brand>

          <div
            className="justify-content-end"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Navbar.Brand>
              <Form.Group controlId="validationFormik02" key={key}>
                <Form.Select
                  aria-label="Default select example"
                  name="branch"
                  size="sm"
                  value={_id}
                  onChange={(e) =>
                    dispatch(
                      setBranch({
                        _id: e.target.value,
                      })
                    )
                  }
                >
                  <option value={""}>select branch</option>

                  {response &&
                    response.data &&
                    response.data?.data &&
                    response.data?.data
                      ?.filter((item) => item.isActive)
                      .map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                </Form.Select>
              </Form.Group>
            </Navbar.Brand>

            <Navbar.Brand>
              <i className="bi bi-bell-fill" style={{ color: "white" }}></i>
            </Navbar.Brand>

            <Navbar.Brand>
              <Dropdown>
                <Dropdown.Toggle variant="light">
                  <span>
                    <i
                      className="bi bi-person-circle"
                      style={{
                        marginLeft: 10,
                      }}
                    ></i>
                    <span>&nbsp;</span>
                    <span
                      style={{
                        marginRight: 10,
                      }}
                    >
                      {userName}
                    </span>
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu align={"end"}>
                  {false && (
                    <Dropdown.Item onClick={() => navigate("/profile")}>
                      Profile
                    </Dropdown.Item>
                  )}
                  {false && (
                    <Dropdown.Item onClick={() => navigate("/password")}>
                      Password
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    onClick={() => {
                      dispatch(
                        setAuth({
                          _id: "",
                          name: "",
                          isAdmin: false,
                          isAuth: false,
                          isBranch: false,
                          isCompany: false,
                          isParent: false,
                          isStudent: false,
                          isTeacher: false,
                        })
                      );

                      localStorage.setItem(
                        "userAuth",
                        JSON.stringify({
                          _id: "",
                          name: "",
                          isAdmin: false,
                          isAuth: false,
                          isBranch: false,
                          isCompany: false,
                          isParent: false,
                          isStudent: false,
                          isTeacher: false,
                        })
                      );

                      navigate("/login");
                    }}
                  >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Navbar.Brand>
          </div>
        </Container>
      </Navbar>
    </div>
  );
};

export default CustomHeader;
