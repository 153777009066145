import { Formik } from "formik";
import queryString from "query-string";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import useFetch from "use-http";
import * as Yup from "yup";
import AppProvider from "../../components/AppProvider";
import CustomLoader from "../../components/CustomLoader";
import CustomPageLayout from "../../components/CustomPageLayout";
import {
  AddDesignationRoot,
  GetAllBranchRoot,
  GetAllDesignationAPIRoot,
  GetFeesMasterByIdRoot,
} from "../../utils/types";

const FeesMaster = () => {
  return (
    <CustomPageLayout Add={Add} Index={Index} Update={Update} View={View} />
  );
};

export interface Root {
  success: boolean;
  message: string;
  data: Daum[];
}

export interface Daum {
  _id: string;
  branch: Branch;
  standard: Standard;
  academicYear: AcademicYear;
  token: string;
  lumpsum: string;
  firstInstalment: string;
  secondInstalment: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface Branch {
  _id: string;
  name: string;
  latitude: string;
  longitude: string;
  radius: string;
  address: string;
  abbreviation: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  isFirstHalf: boolean;
  isSecondHalf: boolean;
  accessCode: string;
  merchantId: string;
  password: string;
  username: string;
  workingKey: string;
}

export interface Standard {
  _id: string;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface AcademicYear {
  _id: string;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

const Index = () => {
  const navigate = useNavigate();
  const { get, post, del } = useFetch<Root>("/feesMaster");
  const [refreshing, setRefreshing] = useState(true);
  const [data, setData] = useState<Daum[]>([]);
  const [key, setKey] = useState(Math.random());

  const [initValues, setInitValues] = useState({
    _id: "",
    token: "0",
    lumpsum: "0",
    firstInstalment: "0",
    secondInstalment: "0",
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (values: {
    _id: string;
    token: string;
    lumpsum: string;
    firstInstalment: string;
    secondInstalment: string;
  }) => {
    setInitValues({ ...values });
    setKey(Math.random());
    setShow(true);
  };

  useEffect(() => {
    (async () => await getData())();
  }, [get]);

  const getData = async () => {
    try {
      setRefreshing(true);
      const res = await get();
      res && res.success && setData(res.data);
      setRefreshing(false);
    } catch (err) {
      setRefreshing(false);
      console.log(err);
    }
  };

  const handleUpdate = async (values: any) => {
    try {
      setRefreshing(true);
      await post("/update", values);
      handleClose();
      setRefreshing(false);
      await getData();
    } catch (err) {
      setRefreshing(false);
      console.log(err);
    }
  };

  const handleDelete = async (_id: string) => {
    try {
      setRefreshing(true);
      await del(`${_id}`);
      await getData();
      setRefreshing(false);
    } catch (err) {
      setRefreshing(false);
      console.log(err);
    }
  };

  if (refreshing) {
    return (
      <>
        <AppProvider>
          <CustomLoader />
        </AppProvider>
      </>
    );
  }

  if (data.length === 0) {
    return (
      <>
        <AppProvider>
          <Container fluid>
            <Card className="mt-3">
              <Card.Body className="d-flex justify-content-between align-items-center">
                <div>NO DATA AVAILABLE</div>

                <div className="d-flex gap-1">
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => navigate("/fees?action=add")}
                  >
                    ADD
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Container>
        </AppProvider>
      </>
    );
  }

  return (
    <>
      <AppProvider>
        <Container fluid>
          <Modal show={show} onHide={handleClose} centered size="xl">
            <Formik
              validationSchema={Yup.object().required({
                token: Yup.string().required(),
                lumpsum: Yup.string().required(),
                firstInstalment: Yup.string().required(),
                secondInstalment: Yup.string().required(),
              })}
              initialValues={initValues}
              onSubmit={handleUpdate}
              key={key}
              enableReinitialize
            >
              {({ handleSubmit, handleChange, values, touched, errors }) => (
                <>
                  <Form onChange={handleChange} onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                      <Modal.Title>IMPORT PARENT DATA</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Token</Form.Label>
                            <Form.Control
                              size="sm"
                              type="number"
                              name="token"
                              value={values.token}
                              onChange={handleChange}
                              isInvalid={!!touched.token && !!errors.token}
                            />
                          </Form.Group>
                        </Col>

                        <Col>
                          <Form.Group>
                            <Form.Label>Lumpsum</Form.Label>
                            <Form.Control
                              size="sm"
                              type="number"
                              name="lumpsum"
                              value={values.lumpsum}
                              onChange={handleChange}
                              isInvalid={!!touched.lumpsum && !!errors.lumpsum}
                            />
                          </Form.Group>
                        </Col>

                        <Col>
                          <Form.Group>
                            <Form.Label>First Instalment</Form.Label>
                            <Form.Control
                              size="sm"
                              type="number"
                              name="firstInstalment"
                              value={values.firstInstalment}
                              onChange={handleChange}
                              isInvalid={
                                !!touched.firstInstalment &&
                                !!errors.firstInstalment
                              }
                            />
                          </Form.Group>
                        </Col>

                        <Col>
                          <Form.Group>
                            <Form.Label>Second Instalment</Form.Label>
                            <Form.Control
                              size="sm"
                              type="number"
                              name="secondInstalment"
                              value={values.secondInstalment}
                              onChange={handleChange}
                              isInvalid={
                                !!touched.secondInstalment &&
                                !!errors.secondInstalment
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button variant="primary" type="submit">
                        Save Changes
                      </Button>
                    </Modal.Footer>
                  </Form>
                </>
              )}
            </Formik>
          </Modal>

          <Card className="mt-2">
            <Card.Header>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: "3rem",
                  alignItems: "center",
                }}
              >
                <h5>Fees</h5>

                {true && (
                  <Button
                    size="sm"
                    onClick={() => navigate("/fees?action=add")}
                  >
                    ADD
                  </Button>
                )}
              </div>
            </Card.Header>

            <Card.Body>
              <Table bordered hover responsive>
                <thead>
                  <tr>
                    <th>SR. NO.</th>
                    <th>STANDARD</th>
                    <th>BRANCH</th>
                    <th>ACADEMIC YEAR</th>
                    <th>TOKEN</th>
                    <th>LUMPSUM</th>
                    <th>FIRST INSTALMENT</th>
                    <th>SECOND INSTALMENT</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={item._id}>
                      <td>{index + 1}</td>
                      <td>{item.standard?.name || ""}</td>
                      <td>{item.branch?.name || ""}</td>
                      <td>{item.academicYear?.name || ""}</td>
                      <td>{item.token || ""}</td>
                      <td>{item.lumpsum || ""}</td>
                      <td>{item.firstInstalment || ""}</td>
                      <td>{item.secondInstalment || ""}</td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            gap: "1rem",
                          }}
                        >
                          <Button
                            onClick={() =>
                              handleShow({
                                _id: item._id || "",
                                firstInstalment: item.firstInstalment || "0",
                                lumpsum: item.lumpsum || "0",
                                secondInstalment: item.secondInstalment || "0",
                                token: item.token || "0",
                              })
                            }
                            size="sm"
                          >
                            UPDATE
                          </Button>
                          <Button
                            size="sm"
                            variant="danger"
                            onClick={() => {
                              Swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, delete it!",
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  handleDelete(item._id);
                                }
                              });
                            }}
                          >
                            DELETE
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Container>
      </AppProvider>
    </>
  );
};

export interface BranchByStandardRoot {
  success: boolean;
  message: string;
  data: BranchByStandardData;
}

export interface BranchByStandardData {
  _id: string;
  standard: string;
  branch: Branch[];
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface Branch {
  _id: string;
  name: string;
  latitude: string;
  longitude: string;
  radius: string;
  address: string;
  abbreviation: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  isFirstHalf: boolean;
  isSecondHalf: boolean;
  accessCode: string;
  merchantId: string;
  password: string;
  username: string;
  workingKey: string;
}

const Add = () => {
  const navigate = useNavigate();
  const { post } = useFetch<AddDesignationRoot>("/feesMaster");
  const { get, response: getBranchByStandardResponse } =
    useFetch<BranchByStandardRoot>("/standardBranch");
  const { get: getStandard, response: getStandardResponse } =
    useFetch<GetAllDesignationAPIRoot>("/standard");
  const { get: getAcademicYear, response: academicYearResponse } =
    useFetch<GetAllDesignationAPIRoot>("/educationYear");
  const [isFetching, setIsFetching] = useState(false);
  const [key, setKey] = useState(Math.random());
  const [branchData, setBranchData] = useState<Branch[]>([]);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  const fetchData = async () => {
    try {
      setIsFetching(true);
      await getStandard();
      await getAcademicYear();
      setKey(Math.random());
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
    }
  };

  const getBranchByStandard = async (_id: string) => {
    try {
      const res = await get(`/standard/${_id}`);
      res.success && setBranchData(res.data.branch);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (values: {
    branch: string;
    standard: string;
    academicYear: string;
    token: string;
    lumpsum: string;
    firstInstalment: string;
    secondInstalment: string;
    isActive: boolean;
  }) => {
    try {
      setIsFetching(true);
      const res = await post(values);
      if (res.success) {
        navigate(-1);
      } else {
        Swal.fire({
          title: "Error",
          text: res.message || "Something went wrong on server.",
          icon: "error",
        });
      }
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      console.log(err);
    }
  };

  if (isFetching) {
    return (
      <AppProvider>
        <CustomLoader />
      </AppProvider>
    );
  }

  return (
    <div key={key}>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Add Fees</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    branch: Yup.string().required(),
                    standard: Yup.string().required(),
                    academicYear: Yup.string().required(),
                    token: Yup.string().required(),
                    lumpsum: Yup.string().required(),
                    firstInstalment: Yup.string().required(),
                    secondInstalment: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={{
                    branch: "",
                    standard: "",
                    academicYear: "",
                    token: "0",
                    lumpsum: "0",
                    firstInstalment: "0",
                    secondInstalment: "0",
                    isActive: true,
                  }}
                >
                  {({
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    values,
                    touched,
                    errors,
                    setFieldValue,
                  }) => (
                    <Form
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onSubmit={handleSubmit}
                    >
                      <GetBranchByStandard
                        key={values.standard}
                        setFieldValue={setFieldValue}
                        standard={values.standard}
                        setBranchData={setBranchData}
                        getBranchByStandard={getBranchByStandard}
                      />

                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Table>
                          <tbody>
                            <tr>
                              <th>Standard</th>
                              <th>
                                <Form.Select
                                  size="sm"
                                  name="standard"
                                  onChange={handleChange}
                                  value={values.standard}
                                  isInvalid={
                                    !!touched.standard && !!errors.standard
                                  }
                                >
                                  <option value={""}>Select</option>
                                  {getStandardResponse.data?.data.map(
                                    (item) => (
                                      <option value={item._id}>
                                        {item.name}
                                      </option>
                                    )
                                  )}
                                </Form.Select>
                              </th>
                            </tr>

                            <tr>
                              <th>Branch</th>
                              <th>
                                <Form.Select
                                  size="sm"
                                  name="branch"
                                  onChange={handleChange}
                                  value={values.branch}
                                  isInvalid={
                                    !!touched.branch && !!errors.branch
                                  }
                                >
                                  <option value={""}>Select</option>
                                  {branchData.map((item) => (
                                    <option value={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </Form.Select>
                              </th>
                            </tr>

                            <tr>
                              <th>Academic Year</th>
                              <th>
                                <Form.Select
                                  size="sm"
                                  name="academicYear"
                                  onChange={handleChange}
                                  value={values.academicYear}
                                  isInvalid={
                                    !!touched.academicYear &&
                                    !!errors.academicYear
                                  }
                                >
                                  <option value={""}>Select</option>
                                  {academicYearResponse.data?.data.map(
                                    (item) => (
                                      <option value={item._id}>
                                        {item.name}
                                      </option>
                                    )
                                  )}
                                </Form.Select>
                              </th>
                            </tr>

                            <tr>
                              <th>Token</th>
                              <th>
                                <Form.Control
                                  size="sm"
                                  type="number"
                                  name="token"
                                  value={values.token}
                                  onChange={handleChange}
                                  isInvalid={!!touched.token && !!errors.token}
                                />
                              </th>
                            </tr>

                            <tr>
                              <th>Lump Sum</th>
                              <th>
                                <Form.Control
                                  size="sm"
                                  type="number"
                                  name="lumpsum"
                                  value={values.lumpsum}
                                  onChange={handleChange}
                                  isInvalid={
                                    !!touched.lumpsum && !!errors.lumpsum
                                  }
                                />
                              </th>
                            </tr>

                            <tr>
                              <th>First Instalment</th>
                              <th>
                                <Form.Control
                                  size="sm"
                                  type="number"
                                  name="firstInstalment"
                                  value={values.firstInstalment}
                                  onChange={handleChange}
                                  isInvalid={
                                    !!touched.firstInstalment &&
                                    !!errors.firstInstalment
                                  }
                                />
                              </th>
                            </tr>

                            <tr>
                              <th>Second Instalment</th>
                              <th>
                                <Form.Control
                                  size="sm"
                                  type="number"
                                  name="secondInstalment"
                                  value={values.secondInstalment}
                                  onChange={handleChange}
                                  isInvalid={
                                    !!touched.secondInstalment &&
                                    !!errors.secondInstalment
                                  }
                                />
                              </th>
                            </tr>

                            <tr>
                              <th>Submit</th>
                              <th>
                                <Button type="submit" size="sm">
                                  SUBMIT
                                </Button>
                              </th>
                            </tr>
                          </tbody>
                        </Table>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const GetBranchByStandard: React.FC<{
  standard: string;
  getBranchByStandard: (arg0: string) => void;
  setFieldValue: (name: string, value: string | number) => void;
  setBranchData: (arg0: Branch[]) => void;
}> = ({ standard, getBranchByStandard, setBranchData, setFieldValue }) => {
  useEffect(() => {
    (async () => {
      setFieldValue("branch", "");
      setBranchData([]);
      if (standard) getBranchByStandard(standard);
    })();
  }, [standard]);

  return <></>;
};

const Update = () => {
  const navigate = useNavigate();

  const { get, post } = useFetch<GetFeesMasterByIdRoot>("/feesMaster");
  const { get: getBranch, response: getBranchResponse } =
    useFetch<GetAllBranchRoot>("/branch");
  const { get: getStandard, response: getStandardResponse } =
    useFetch<GetAllDesignationAPIRoot>("/standard");
  const { get: getAcademicYear, response: academicYearResponse } =
    useFetch<GetAllDesignationAPIRoot>("/educationYear");

  const { search } = useLocation();
  const parsed = queryString.parse(search);

  const [initValues, setInitValues] = useState({
    // branch: "",
    standard: "",
    academicYear: "",
    token: "",
    lumpsum: "",
    firstInstalment: "",
    secondInstalment: "",
    isActive: true,
  });

  const [key, setKey] = useState(Math.random());
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    (async () => {
      parsed.id && (await fetchData());
    })();
  }, [parsed.id, get]);

  const fetchData = async () => {
    try {
      setIsFetching(true);

      await getBranch();
      await getStandard();
      await getAcademicYear();

      const res = await get(`${parsed.id}`);

      if (res.success) {
        setInitValues({
          academicYear: res.data.academicYear,
          firstInstalment: res.data.firstInstalment,
          isActive: res.data.isActive,
          lumpsum: res.data.lumpsum,
          secondInstalment: res.data.secondInstalment,
          standard: res.data.standard,
          token: res.data.token,
        });

        setKey(Math.random());
      }

      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);

      console.log(err);
    }
  };

  const handleSubmit = async (values: {
    // branch: string;
    standard: string;
    academicYear: string;
    token: string;
    lumpsum: string;
    firstInstalment: string;
    secondInstalment: string;
    isActive: boolean;
  }) => {
    try {
      setIsFetching(true);

      console.log(values);

      await post(`${parsed.id}`, values);

      navigate(-1);

      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);

      console.log(err);
    }
  };

  if (isFetching) {
    return (
      <>
        <AppProvider>
          <CustomLoader />
        </AppProvider>
      </>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Update Fees</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    // branch: Yup.string().required(),
                    standard: Yup.string().required(),
                    academicYear: Yup.string().required(),
                    token: Yup.string().required(),
                    lumpsum: Yup.string().required(),
                    firstInstalment: Yup.string().required(),
                    secondInstalment: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={initValues}
                  enableReinitialize
                  key={key}
                >
                  {({
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onSubmit={handleSubmit}
                    >
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Table>
                          <tbody>
                            {/* <tr>
                              <th>Branch</th>
                              <th>
                                <Form.Select
                                  size="sm"
                                  name="branch"
                                  onChange={handleChange}
                                  value={values.branch}
                                  isInvalid={
                                    !!touched.branch && !!errors.branch
                                  }
                                  disabled
                                >
                                  <option value={""}>Select</option>
                                  {getBranchResponse.data?.data.map((item) => (
                                    <option value={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </Form.Select>
                              </th>
                            </tr> */}

                            <tr>
                              <th>Standard</th>
                              <th>
                                <Form.Select
                                  size="sm"
                                  name="standard"
                                  onChange={handleChange}
                                  value={values.standard}
                                  isInvalid={
                                    !!touched.standard && !!errors.standard
                                  }
                                  disabled
                                >
                                  <option value={""}>Select</option>
                                  {getStandardResponse.data?.data.map(
                                    (item) => (
                                      <option value={item._id}>
                                        {item.name}
                                      </option>
                                    )
                                  )}
                                </Form.Select>
                              </th>
                            </tr>

                            <tr>
                              <th>Academic Year</th>
                              <th>
                                <Form.Select
                                  size="sm"
                                  name="academicYear"
                                  onChange={handleChange}
                                  value={values.academicYear}
                                  isInvalid={
                                    !!touched.academicYear &&
                                    !!errors.academicYear
                                  }
                                >
                                  <option value={""}>Select</option>
                                  {academicYearResponse.data?.data.map(
                                    (item) => (
                                      <option value={item._id}>
                                        {item.name}
                                      </option>
                                    )
                                  )}
                                </Form.Select>
                              </th>
                            </tr>

                            <tr>
                              <th>Token</th>
                              <th>
                                <Form.Control
                                  size="sm"
                                  type="number"
                                  name="token"
                                  value={values.token}
                                  onChange={handleChange}
                                  isInvalid={!!touched.token && !!errors.token}
                                />
                              </th>
                            </tr>

                            <tr>
                              <th>Lump Sum</th>
                              <th>
                                <Form.Control
                                  size="sm"
                                  type="number"
                                  name="lumpsum"
                                  value={values.lumpsum}
                                  onChange={handleChange}
                                  isInvalid={
                                    !!touched.lumpsum && !!errors.lumpsum
                                  }
                                />
                              </th>
                            </tr>

                            <tr>
                              <th>First Instalment</th>
                              <th>
                                <Form.Control
                                  size="sm"
                                  type="number"
                                  name="firstInstalment"
                                  value={values.firstInstalment}
                                  onChange={handleChange}
                                  isInvalid={
                                    !!touched.firstInstalment &&
                                    !!errors.firstInstalment
                                  }
                                />
                              </th>
                            </tr>

                            <tr>
                              <th>Second Instalment</th>
                              <th>
                                <Form.Control
                                  size="sm"
                                  type="number"
                                  name="secondInstalment"
                                  value={values.secondInstalment}
                                  onChange={handleChange}
                                  isInvalid={
                                    !!touched.secondInstalment &&
                                    !!errors.secondInstalment
                                  }
                                />
                              </th>
                            </tr>

                            <tr>
                              <th>Submit</th>
                              <th>
                                <Button type="submit" size="sm">
                                  SUBMIT
                                </Button>
                              </th>
                            </tr>
                          </tbody>
                        </Table>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const View = () => {
  return <div></div>;
};

export default FeesMaster;
