import queryString from "query-string";
import { ElementType, FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AppProvider from "./AppProvider";
import CustomLoader from "./CustomLoader";

const CustomPageLayout: FC<{
  Index: ElementType;
  Add: ElementType;
  Update: ElementType;
  View: ElementType;
}> = ({ Add, Index, Update, View }) => {
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const parsed = queryString.parse(search);

  useEffect(() => {
    // Simulating loading delay with a timeout
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    // Cleanup function
    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    setIsLoading(true);
  }, [search]);

  if (isLoading) {
    // You can replace this with your loading indicator component
    return (
      <>
        <AppProvider>
          <CustomLoader />
        </AppProvider>
      </>
    );
  }

  if (parsed.action === "add") {
    return <Add />;
  } else if (parsed.action === "update") {
    return <Update />;
  } else if (parsed.action === "view") {
    return <View />;
  } else {
    return <Index />;
  }
};

export default CustomPageLayout;
