import moment from "moment";
import { useEffect, useState } from "react";
import { Card, Container, Spinner, Table } from "react-bootstrap";
import useFetch from "use-http";
import AppProvider from "../../components/AppProvider";

export interface Root {
  success: boolean;
  message: string;
  data: Daum[];
}

export interface Daum {
  _id: string;
  name: string;
  username: string;
  date: string;
  scplConnectAndroidLoginCount: number;
  scplConnectAndroidForegroundCount: number;
  scplConnectAndroidBackgroundCount: number;
  scplConnectAndroidLogoutCount: number;
  scplConnectIosLoginCount: number;
  scplConnectIosForegroundCount: number;
  scplConnectIosBackgroundCount: number;
  scplConnectIosLogoutCount: number;
  scplTeamAndroidLoginCount: number;
  scplTeamAndroidForegroundCount: number;
  scplTeamAndroidBackgroundCount: number;
  scplTeamAndroidLogoutCount: number;
  scplTeamIosLoginCount: number;
  scplTeamIosForegroundCount: number;
  scplTeamIosBackgroundCount: number;
  scplTeamIosLogoutCount: number;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

const AppUserActivity = () => {
  const { get, response } = useFetch<Root>("/appUserActivity");
  const [isFetching, setIsFetching] = useState(false);
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetching(true);

        await get();

        setIsFetching(false);

        setKey(Math.random());
      } catch (err) {
        setIsFetching(false);
        console.log(err);
      }
    };

    fetchData();
  }, []);

  if (isFetching) {
    return (
      <AppProvider>
        <Spinner />
      </AppProvider>
    );
  }

  return (
    <AppProvider>
      <Container fluid className="mt-3">
        <Card key={key}>
          <Card.Body>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th rowSpan={3}>Sr. No.</th>
                  <th rowSpan={3}>Name</th>
                  <th rowSpan={3}>Date</th>
                  <th colSpan={8}>SCPL CONNECT</th>
                  <th colSpan={8}>SCPL TEAM</th>
                </tr>

                <tr>
                  <th colSpan={4}>Android</th>
                  <th colSpan={4}>Ios</th>
                  <th colSpan={4}>Android</th>
                  <th colSpan={4}>Ios</th>
                </tr>

                <tr>
                  <th>Login</th>
                  <th>Logout</th>
                  <th>Foreground</th>
                  <th>Background</th>

                  <th>Login</th>
                  <th>Logout</th>
                  <th>Foreground</th>
                  <th>Background</th>

                  <th>Login</th>
                  <th>Logout</th>
                  <th>Foreground</th>
                  <th>Background</th>

                  <th>Login</th>
                  <th>Logout</th>
                  <th>Foreground</th>
                  <th>Background</th>
                </tr>
              </thead>
              <tbody>
                {/* {JSON.stringify(response)} */}
                {response.data?.data?.map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>

                      <td>{item.name}</td>
                      <td>{moment(item.date).format("DD-MM-YYYY")}</td>

                      <td>{item.scplConnectAndroidLoginCount}</td>
                      <td>{item.scplConnectAndroidLogoutCount}</td>
                      <td>{item.scplConnectAndroidForegroundCount}</td>
                      <td>{item.scplConnectAndroidBackgroundCount}</td>

                      <td>{item.scplConnectIosLoginCount}</td>
                      <td>{item.scplConnectIosLogoutCount}</td>
                      <td>{item.scplConnectIosForegroundCount}</td>
                      <td>{item.scplConnectIosBackgroundCount}</td>

                      <td>{item.scplTeamAndroidLoginCount}</td>
                      <td>{item.scplTeamAndroidLogoutCount}</td>
                      <td>{item.scplTeamAndroidForegroundCount}</td>
                      <td>{item.scplTeamAndroidBackgroundCount}</td>

                      <td>{item.scplTeamIosLoginCount}</td>
                      <td>{item.scplTeamIosLogoutCount}</td>
                      <td>{item.scplTeamIosForegroundCount}</td>
                      <td>{item.scplTeamIosBackgroundCount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    </AppProvider>
  );
};

export default AppUserActivity;
