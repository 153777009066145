import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Card, Container, Form, Row, Table } from "react-bootstrap";
import useFetch from "use-http";
import * as Yup from "yup";
import AppProvider from "../../components/AppProvider";
import CustomLoader from "../../components/CustomLoader";

const UpdateAcademicYear = () => {
  const [isFetching, setIsFetching] = useState(false);
  const { get, post, response } = useFetch("/educationYear");
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    (async () => await fetchData())();
  }, []);

  const fetchData = async () => {
    try {
      setIsFetching(true);
      await get();
      setKey(Math.random());
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      console.log(err);
    }
  };

  const handleSubmit = async (values: { academicYear: string }) => {
    try {
      setIsFetching(true);
      await post("/update", values);
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      console.log(err);
    }
  };

  if (isFetching) {
    return (
      <>
        <AppProvider>
          <CustomLoader />
        </AppProvider>
      </>
    );
  }

  return (
    <>
      <AppProvider>
        <Container fluid>
          <Card className="mt-2">
            <Card.Header>Update Student Academic Year</Card.Header>
            <Card.Body>
              <Formik
                initialValues={{
                  academicYear: "",
                }}
                validationSchema={Yup.object().shape({
                  academicYear: Yup.string().required(),
                })}
                onSubmit={handleSubmit}
              >
                {({
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  touched,
                  values,
                }) => (
                  <>
                    <Form
                      onChange={handleChange}
                      onSubmit={handleSubmit}
                      onBlur={handleBlur}
                    >
                      <Row>
                        <Table bordered responsive>
                          <tbody key={key}>
                            <tr>
                              <th>Academic Year</th>
                              <td>
                                <Form.Select
                                  size="sm"
                                  name="academicYear"
                                  onChange={handleChange}
                                  value={values.academicYear}
                                  isInvalid={
                                    !!touched.academicYear &&
                                    !!errors.academicYear
                                  }
                                >
                                  <option value={""}>Select</option>
                                  {response.data?.data.map((item: any) => (
                                    <option value={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </Form.Select>
                              </td>
                            </tr>

                            <tr>
                              <th>Submit</th>
                              <td>
                                <Button type="submit" size="sm">
                                  SUBMIT
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Row>
                    </Form>
                  </>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Container>
      </AppProvider>
    </>
  );
};

export default UpdateAcademicYear;
