import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Card, Container, Form, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import useFetch, { FetchData } from "use-http";
import * as Yup from "yup";
import AppProvider from "../../components/AppProvider";
import CustomLoader from "../../components/CustomLoader";
import {
  GetAllBranchRoot,
  GetAllDesignationAPIRoot,
  GetAllStudentDaum,
} from "../../utils/types";
import { PaymentHistoryRoot } from "./AddPaymentHistory";

export interface Root {
  success: boolean;
  message: string;
  data: Daum[];
}

export interface Daum {
  _id: string;
  name: string;
  username: string;
  contact: string;
  fatherContact: string;
  motherContact: string;
  standard: Standard;
  branch: Branch;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  academicYear: AcademicYear;
  address?: string;
  batch?: Batch;
  board?: Board;
  dateOfBirth?: string;
  email?: string;
  gender?: Gender;
  school?: School;
  firstInstalment: string;
  lumpsum: string;
  secondInstalment: string;
  token: string;
}

export interface Standard {
  _id: string;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface Branch {
  _id: string;
  name: string;
  latitude: string;
  longitude: string;
  radius: string;
  address: string;
  abbreviation: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  isFirstHalf: boolean;
  isSecondHalf: boolean;
  accessCode: string;
  merchantId: string;
  password: string;
  username: string;
  workingKey: string;
}

export interface AcademicYear {
  _id: string;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface Batch {
  _id: string;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface Board {
  _id: string;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface Gender {
  _id: string;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface School {
  _id: string;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

const ManualFeesAdd = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [key, setKey] = useState(Math.random());

  const [studentData, setStudentData] = useState<Daum[]>([]);
  const [paidOptions, setPaidOptions] = useState({
    isTokenPaid: false,
    isLumpsumPaid: false,
    isFirstInstalmentPaid: false,
    isSecondInstalmentPaid: false,
  });
  const [amountData, setAmountData] = useState({
    tokenAmount: 0,
    lumpSumAmount: 0,
    firstInstalmentAmount: 0,
    secondInstalmentAmount: 0,
  });

  const { get: getBranch, response: getBranchResponse } =
    useFetch<GetAllBranchRoot>("/branch");
  const { post: getStudent, response: getStudentResponse } =
    useFetch<Root>("/student");
  const { get: getAcademicYear, response: academicYearResponse } =
    useFetch<GetAllDesignationAPIRoot>("/educationYear");
  const { post: getPaymentHistoryMaster } =
    useFetch<PaymentHistoryRoot>("/payment");
  const { post: addFees } = useFetch("/payment");

  useEffect(() => {
    (async () => await fetchData())();
  }, []);

  const fetchData = async () => {
    try {
      setIsFetching(true);

      setAmountData({
        tokenAmount: 0,
        lumpSumAmount: 0,
        firstInstalmentAmount: 0,
        secondInstalmentAmount: 0,
      });

      setPaidOptions({
        isTokenPaid: false,
        isLumpsumPaid: false,
        isFirstInstalmentPaid: false,
        isSecondInstalmentPaid: false,
      });

      await getBranch();
      await getAcademicYear();

      setIsFetching(false);

      setKey(Math.random());
    } catch (err) {
      setIsFetching(false);
      console.log(err);
    }
  };

  const handleSubmit = async (values: {
    branch: string;
    student: string;
    academicYear: string;
    paymentOption: string;
    standard: string;
    remark: string;
  }) => {
    try {
      if (paidOptions.isLumpsumPaid) {
        alert("Fees already paid for this year");

        return;
      } else if (
        values.paymentOption === "3" &&
        paidOptions.isFirstInstalmentPaid === false
      ) {
        alert("Only first instalment allowed first");

        return;
      } else if (
        values.paymentOption === "0" &&
        paidOptions.isFirstInstalmentPaid
      ) {
        alert("Not allowed. Now You can pay second instalment only.");

        return;
      }

      let tempAmount = 0;

      if (values.paymentOption === "0") {
        tempAmount = amountData.tokenAmount;
      } else if (values.paymentOption === "1") {
        tempAmount = paidOptions.isTokenPaid
          ? amountData.lumpSumAmount - amountData.tokenAmount
          : amountData.lumpSumAmount;
      } else if (values.paymentOption === "2") {
        tempAmount = paidOptions.isTokenPaid
          ? amountData.firstInstalmentAmount - amountData.tokenAmount
          : amountData.firstInstalmentAmount;
      } else if (values.paymentOption === "3") {
        tempAmount = amountData.secondInstalmentAmount;
      }

      const payload = {
        student: values.student,
        branch: values.branch,
        standard: values.standard,
        academicYear: values.academicYear,
        amount: tempAmount,
        isToken: values.paymentOption === "0" ? true : false,
        isLumpSum: values.paymentOption === "1" ? true : false,
        isFirstInstalment: values.paymentOption === "2" ? true : false,
        isSecondInstalment: values.paymentOption === "3" ? true : false,
        remark: values.remark,
      };

      await addFees("/addPayment", payload);

      toast.success("Record added");
    } catch (err) {
      console.log(err);
    }
  };

  if (isFetching) {
    return (
      <>
        <AppProvider>
          <CustomLoader />
        </AppProvider>
      </>
    );
  }

  return (
    <>
      <AppProvider>
        <Container fluid>
          <Card className="mt-3">
            <Card.Header>
              <h5>MANUAL FEES ADD</h5>
            </Card.Header>
            <Card.Body>
              <Formik
                initialValues={{
                  branch: "",
                  student: "",
                  academicYear: "",
                  paymentOption: "",
                  standard: "",
                  remark: "OFFLINE PAYMENT",
                }}
                onSubmit={handleSubmit}
                validationSchema={Yup.object().shape({
                  branch: Yup.string().required(),
                  student: Yup.string().required(),
                  academicYear: Yup.string().required(),
                  paymentOption: Yup.string().required(),
                  remark: Yup.string().required(),
                })}
              >
                {({
                  errors,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <>
                    <Form
                      onChange={handleChange}
                      onSubmit={handleSubmit}
                      key={key}
                    >
                      <Table responsive>
                        <tbody>
                          <tr>
                            <th>Branch</th>
                            <td>
                              <Form.Select
                                value={values.branch}
                                size="sm"
                                name="branch"
                                isInvalid={!!touched.branch && !!errors.branch}
                              >
                                <option value={""}>select branch</option>
                                {getBranchResponse.data?.data.map((item) => (
                                  <option value={item._id}>{item.name}</option>
                                ))}
                              </Form.Select>

                              <GetStudentByBranch
                                key={values.branch}
                                branch={values.branch}
                                getStudent={getStudent}
                                setFieldValue={setFieldValue}
                                setStudentData={setStudentData}
                              />
                            </td>
                          </tr>

                          <>
                            <tr>
                              <th>Student</th>
                              <td>
                                <Form.Select
                                  value={values.student}
                                  size="sm"
                                  name="student"
                                  isInvalid={
                                    !!touched.student && !!errors.student
                                  }
                                  key={values.branch}
                                >
                                  <option value={""}>select student</option>
                                  {studentData.map((item) => (
                                    <option value={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </Form.Select>
                              </td>
                            </tr>

                            <tr>
                              <th>Academic Year</th>
                              <td>
                                <Form.Select
                                  value={values.academicYear}
                                  size="sm"
                                  name="academicYear"
                                  isInvalid={
                                    !!touched.academicYear &&
                                    !!errors.academicYear
                                  }
                                >
                                  <option value={""}>
                                    select academic year
                                  </option>
                                  {academicYearResponse?.data?.data.map(
                                    (item) => (
                                      <option value={item._id}>
                                        {item.name}
                                      </option>
                                    )
                                  )}
                                </Form.Select>
                              </td>
                            </tr>

                            <SetStandard
                              key={values.student}
                              setFieldValue={setFieldValue}
                              student={values.student}
                              studentData={getStudentResponse.data?.data || []}
                            />

                            <GetFeesHistory
                              key={
                                values.student +
                                values.branch +
                                values.academicYear
                              }
                              getPaymentHistory={getPaymentHistoryMaster}
                              academicYear={values.academicYear}
                              student={values.student}
                              setPaidOptions={setPaidOptions}
                            />
                          </>

                          <SetFieldValue
                            academicYear={values.academicYear}
                            branch={values.branch}
                            setFieldValue={setFieldValue}
                            student={values.student}
                            setAmountData={setAmountData}
                            setPaidOptions={setPaidOptions}
                            studentData={studentData}
                          />

                          <tr>
                            <th>Payment Option</th>
                            <td>
                              <Form.Select
                                key={
                                  values.academicYear +
                                  values.branch +
                                  values.student
                                }
                                value={values.paymentOption}
                                size="sm"
                                name="paymentOption"
                                isInvalid={
                                  !!touched.paymentOption &&
                                  !!errors.paymentOption
                                }
                              >
                                <option value={""}>
                                  select payment option
                                </option>
                                <option
                                  value={"0"}
                                  disabled={paidOptions.isTokenPaid}
                                >
                                  Token {amountData.tokenAmount}
                                  {paidOptions.isTokenPaid && " PAID"}
                                </option>
                                <option
                                  value={"1"}
                                  disabled={paidOptions.isLumpsumPaid}
                                >
                                  Lump Sum{" "}
                                  {paidOptions.isTokenPaid
                                    ? amountData.lumpSumAmount -
                                      amountData.tokenAmount
                                    : amountData.lumpSumAmount}
                                  {paidOptions.isLumpsumPaid && " PAID"}
                                </option>
                                <option
                                  value={"2"}
                                  disabled={paidOptions.isFirstInstalmentPaid}
                                >
                                  First Instalment{" "}
                                  {paidOptions.isTokenPaid
                                    ? amountData.firstInstalmentAmount -
                                      amountData.tokenAmount
                                    : amountData.firstInstalmentAmount}
                                  {paidOptions.isFirstInstalmentPaid && " PAID"}
                                </option>
                                <option
                                  value={"3"}
                                  disabled={paidOptions.isSecondInstalmentPaid}
                                >
                                  Second Instalment{" "}
                                  {amountData.secondInstalmentAmount}
                                  {paidOptions.isSecondInstalmentPaid &&
                                    " PAID"}
                                </option>
                              </Form.Select>
                            </td>
                          </tr>

                          <tr>
                            <th>Remark</th>
                            <th>
                              <Form.Control
                                size="sm"
                                type="text"
                                name="remark"
                                value={values.remark}
                                onChange={handleChange}
                                isInvalid={!!touched.remark && !!errors.remark}
                              />
                            </th>
                          </tr>

                          <tr>
                            <th>Submit</th>
                            <th>
                              <Button type="submit" size="sm">
                                SUBMIT
                              </Button>
                            </th>
                          </tr>
                        </tbody>
                      </Table>
                    </Form>
                  </>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Container>
      </AppProvider>
    </>
  );
};

const GetStudentByBranch: React.FC<{
  branch: string;
  getStudent: FetchData<Root>;
  setFieldValue: (name: string, value: string | number) => void;
  setStudentData: (arg0: Daum[]) => void;
}> = ({ branch, setFieldValue, getStudent, setStudentData }) => {
  useEffect(() => {
    (async () => {
      setStudentData([]);
      setFieldValue("student", "");
      setFieldValue("paymentOption", "");

      if (branch) {
        await getStudent("/byBranch", {
          branchId: branch,
        }).then((res) => res.success && setStudentData(res.data));
      }
    })();
  }, [branch]);

  return <></>;
};

const SetStandard: React.FC<{
  setFieldValue: (name: string, value: string | number) => void;
  student: string;
  studentData: GetAllStudentDaum[];
}> = ({ setFieldValue, student, studentData }) => {
  useEffect(() => {
    setFieldValue("standard", "");

    let temp = studentData.find((item) => item._id === student);

    setFieldValue("standard", temp?.standard?._id || "");
  }, [student]);

  return <></>;
};

const GetFeesHistory: React.FC<{
  getPaymentHistory: FetchData<PaymentHistoryRoot>;
  student: string;
  academicYear: string;
  setPaidOptions: (arg0: {
    isTokenPaid: boolean;
    isLumpsumPaid: boolean;
    isFirstInstalmentPaid: boolean;
    isSecondInstalmentPaid: boolean;
  }) => void;
}> = ({ getPaymentHistory, academicYear, student, setPaidOptions }) => {
  useEffect(() => {
    (async () => {
      setPaidOptions({
        isTokenPaid: false,
        isLumpsumPaid: false,
        isFirstInstalmentPaid: false,
        isSecondInstalmentPaid: false,
      });

      if (student && academicYear) {
        await getPaymentHistory("/history", {
          student: student,
          academicYear: academicYear,
        }).then((res) => {
          if (res?.success) {
            let temp = {
              isTokenPaid: false,
              isLumpsumPaid: false,
              isFirstInstalmentPaid: false,
              isSecondInstalmentPaid: false,
            };

            if (res.success) {
              const isTokenPaid = res.data?.find(
                (x) => x.paymentConfirmation && x.isToken
              );
              if (isTokenPaid) temp.isTokenPaid = true;

              const isLumpsumPaid = res.data?.find(
                (x) => x.paymentConfirmation && x.isLumpSum
              );
              if (isLumpsumPaid) temp.isLumpsumPaid = true;

              const isFirstInstalmentPaid = res.data?.find(
                (x) => x.paymentConfirmation && x.isFirstInstalment
              );
              if (isFirstInstalmentPaid) temp.isFirstInstalmentPaid = true;

              const isSecondInstalmentPaid = res.data?.find(
                (x) => x.paymentConfirmation && x.isSecondInstalment
              );
              if (isSecondInstalmentPaid) temp.isTokenPaid = true;

              setPaidOptions(temp);
            }
          }
        });
      }
    })();
  }, [student, academicYear]);

  return <></>;
};

const SetFieldValue: React.FC<{
  branch: string;
  student: string;
  academicYear: string;
  setFieldValue: (name: string, value: string | number) => void;
  setPaidOptions: (arg0: {
    isTokenPaid: boolean;
    isLumpsumPaid: boolean;
    isFirstInstalmentPaid: boolean;
    isSecondInstalmentPaid: boolean;
  }) => void;
  setAmountData: (arg0: {
    tokenAmount: number;
    lumpSumAmount: number;
    firstInstalmentAmount: number;
    secondInstalmentAmount: number;
  }) => void;
  studentData: Daum[];
}> = ({
  academicYear,
  branch,
  setFieldValue,
  student,
  setAmountData,
  setPaidOptions,
  studentData,
}) => {
  useEffect(() => {
    (async () => {
      setFieldValue("paymentOption", "");

      setAmountData({
        firstInstalmentAmount: 0,
        lumpSumAmount: 0,
        secondInstalmentAmount: 0,
        tokenAmount: 0,
      });

      setPaidOptions({
        isTokenPaid: false,
        isLumpsumPaid: false,
        isFirstInstalmentPaid: false,
        isSecondInstalmentPaid: false,
      });

      if (academicYear && branch && student) {
        let temp = studentData.find((x) => x._id === student);

        setAmountData({
          firstInstalmentAmount: parseInt(temp?.firstInstalment || "0"),
          lumpSumAmount: parseInt(temp?.lumpsum || "0"),
          secondInstalmentAmount: parseInt(temp?.secondInstalment || "0"),
          tokenAmount: parseInt(temp?.token || "0"),
        });
      }
    })();
  }, [academicYear, branch, student]);

  return <></>;
};

export default ManualFeesAdd;
