import { useEffect, useState } from "react";
import { Button, Card, Container, Table } from "react-bootstrap";

const DATA = [
  {
    id: 0,
    name: "brush",
    completed: "yes",
  },
  {
    id: 1,
    name: "breakfast",
    completed: "no",
  },
  {
    id: 2,
    name: "homework",
    completed: "no",
  },
];

/**
 * TO-DO LIST
 *
 * 1. DESCRIPTION
 * 2. DEVIDE IN SMALL TASK
 *
 * 1. UI IN NOTEBOOK
 * 2. UI IN HTML
 * 3. UI IN REACT
 * 4. STATIC DATA DISPLAY
 * 5. START ADD
 * 6. UPDATE
 * 7. DELETE
 */

const Intern = () => {
  const [count, setCount] = useState<number>(1000);

  useEffect(() => {}, []);

  return (
    <>
      <Container>
        <Card className="mt-2">
          <Card.Header>LEARNING HOOKS</Card.Header>
          <Card.Body>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th>SR. NO.</th>
                  <th>NAME</th>
                  <th>SYNTEX</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>USE STATE</td>
                  <td>
                    <p>const [count,setCount] = useState(0)</p>
                    <p>const [name,setName] = useState("")</p>{" "}
                    <p>
                      const [data,setData] = useState({`{name:"",`}
                      {`email:"",`}
                      {`password:""}`})
                    </p>
                    <p>
                      const [task,setTask] = useState({`[{name:"",`}
                      {`completed:""]`})
                    </p>
                  </td>
                </tr>

                <tr>
                  <td>2</td>
                  <td>USE EFFECT</td>
                  <td>
                    useEffect( () ={`>`} {`{}`},[] )
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>

        <Card className="mt-2">
          <Card.Header>LEARNING HOOKS EXAMPLES</Card.Header>
          <Card.Body>
            <div className="d-flex gap-2">
              <Button
                onClick={() => {
                  setCount((count) => count - 1);
                }}
              >
                -
              </Button>
              <span>{count}</span>
              <Button
                onClick={() => {
                  setCount((count) => count + 1);
                }}
              >
                +
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default Intern;
