import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import useFetch from "use-http";
import * as Yup from "yup";
import AppProvider from "../../components/AppProvider";
import CustomLoader from "../../components/CustomLoader";
import { GetAllBranchRoot, GetAllDesignationAPIRoot } from "../../utils/types";

const UpdateStandard = () => {
  const { post, response } = useFetch("/student");
  const { get: getBranch, response: getBranchResponse } =
    useFetch<GetAllBranchRoot>("/branch");
  const { get: getStandard, response: getStandardResponse } =
    useFetch<GetAllDesignationAPIRoot>("/standard");
  const [isFetching, setIsFetching] = useState(false);
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    (async () => await fetchData())();
  }, []);

  const fetchData = async () => {
    try {
      setIsFetching(true);
      await getBranch();
      await getStandard();
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      console.log(err);
    }
  };

  const handleSubmit = async (values: {
    branch: string;
    oldStandard: string;
    newStandard: string;
  }) => {
    try {
      setIsFetching(true);

      const res = await post("/updateStandard", {
        ...values,
      });

      console.log(res);

      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      console.log(err);
    }
  };

  if (isFetching) {
    return (
      <AppProvider>
        <CustomLoader />
      </AppProvider>
    );
  }

  return (
    <AppProvider>
      <Container fluid key={key}>
        <Card className="mt-3">
          <Card.Header>
            <h5>UPDATE STANDARD</h5>
          </Card.Header>

          <Card.Body>
            <Formik
              initialValues={{
                branch: "",
                oldStandard: "",
                newStandard: "",
              }}
              validationSchema={Yup.object().shape({
                branch: Yup.string().required(),
                oldStandard: Yup.string().required(),
                newStandard: Yup.string().required(),
              })}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => {
                return (
                  <>
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                          <Form.Group className="mb-3">
                            <Form.Label>Branch</Form.Label>
                            <Form.Select
                              size="sm"
                              name="branch"
                              isInvalid={!!touched.branch && !!errors.branch}
                            >
                              <option value={""}>select branch</option>
                              {getBranchResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                          <Form.Group className="mb-3">
                            <Form.Label>Old standard</Form.Label>
                            <Form.Select
                              size="sm"
                              name="oldStandard"
                              isInvalid={
                                !!touched.oldStandard && !!errors.oldStandard
                              }
                            >
                              <option>select old standard</option>
                              {getStandardResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                          <Form.Group className="mb-3">
                            <Form.Label>New standard</Form.Label>
                            <Form.Select
                              size="sm"
                              name="newStandard"
                              isInvalid={
                                !!touched.newStandard && !!errors.newStandard
                              }
                            >
                              <option>select new standard</option>
                              {getStandardResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                          <Button type="submit">Submit</Button>
                        </Col>
                      </Row>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </Card.Body>
        </Card>
      </Container>
    </AppProvider>
  );
};

export default UpdateStandard;
