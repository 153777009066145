import { Formik } from "formik";
import queryString from "query-string";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import useFetch, { FetchData } from "use-http";
import * as Yup from "yup";
import AppProvider from "../../components/AppProvider";
import CustomLoader from "../../components/CustomLoader";
import CustomPageLayout from "../../components/CustomPageLayout";
import {
  AddDesignationRoot,
  GetAllDesignationAPIRoot,
  GetAllStudentDaum,
  GetAllStudentRoot,
  GetFeesDiscountMasterByIdRoot,
} from "../../utils/types";

const FeesDiscountMaster = () => {
  return (
    <CustomPageLayout Add={Add} Index={Index} Update={Update} View={View} />
  );
};

export interface Root {
  success: boolean;
  message: string;
  data: Daum[];
}

export interface Daum {
  _id: string;
  branch: Branch;
  student: Student;
  standard: Standard;
  academicYear: AcademicYear;
  token: string;
  lumpsum: string;
  firstInstalment: string;
  secondInstalment: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface Branch {
  _id: string;
  name: string;
  latitude: string;
  longitude: string;
  radius: string;
  address: string;
  abbreviation: string;
  isFirstHalf: boolean;
  isSecondHalf: boolean;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  accessCode: string;
  merchantId: string;
  password: string;
  username: string;
  workingKey: string;
}

export interface Student {
  _id: string;
  name: string;
  username: string;
  contact: string;
  fatherContact: string;
  motherContact: string;
  standard: string;
  branch: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  address: string;
  batch: string;
  board: string;
  dateOfBirth: string;
  email: string;
  gender: string;
  school: string;
  academicYear: string;
  photo: string;
}

export interface Standard {
  _id: string;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface AcademicYear {
  _id: string;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

const Index = () => {
  const navigate = useNavigate();
  const {
    get,
    delete: deleteDesignation,
    response,
  } = useFetch<Root>("/feesDiscountMaster");
  const [key, setKey] = useState(Math.random());
  const [key2, setKey2] = useState(Math.random());
  const [refreshing, setRefreshing] = useState(true);
  const [initValues, setInitValues] = useState({
    token: "0",
    lumpsum: "0",
    firstInstalment: "0",
    secondInstalment: "0",
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (values: {
    token: string;
    lumpsum: string;
    firstInstalment: string;
    secondInstalment: string;
  }) => {
    setInitValues({ ...values });
    setKey2(Math.random());
    setShow(true);
  };

  useEffect(() => {
    (async () => await fetchData())();
  }, []);

  const fetchData = async () => {
    try {
      setRefreshing(true);
      await get();
      setKey(Math.random());
      setRefreshing(false);
    } catch (err) {
      setRefreshing(false);
      console.log(err);
    }
  };

  const handleDelete = async (_id: string) => {
    setRefreshing(true);

    await deleteDesignation(`${_id}`).then((res) => {
      if (res.success) {
        Swal.fire({
          title: "Deleted!",
          text: "Your data has been deleted.",
          icon: "success",
        });
      }
    });
    await fetchData();

    setRefreshing(false);
  };

  const handleUpdate = async (values: {
    token: string;
    lumpsum: string;
    firstInstalment: string;
    secondInstalment: string;
  }) => {
    try {
    } catch (err) {
      console.log(err);
    }
  };

  if (refreshing) {
    return (
      <AppProvider>
        <CustomLoader />
      </AppProvider>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
            fluid
          >
            <Modal show={show} onHide={handleClose} centered size="xl">
              <Formik
                validationSchema={Yup.object().shape({
                  token: Yup.string().required(),
                  lumpsum: Yup.string().required(),
                  firstInstalment: Yup.string().required(),
                  secondInstalment: Yup.string().required(),
                })}
                initialValues={initValues}
                onSubmit={handleUpdate}
                key={key2}
                enableReinitialize
              >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                  <>
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Modal.Header closeButton>
                        <Modal.Title>UPDATE</Modal.Title>
                      </Modal.Header>

                      <Modal.Body>
                        <Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>Token</Form.Label>
                              <Form.Control
                                size="sm"
                                type="number"
                                name="token"
                                value={values.token}
                                onChange={handleChange}
                                isInvalid={!!touched.token && !!errors.token}
                              />
                            </Form.Group>
                          </Col>

                          <Col>
                            <Form.Group>
                              <Form.Label>Lumpsum</Form.Label>
                              <Form.Control
                                size="sm"
                                type="number"
                                name="lumpsum"
                                value={values.lumpsum}
                                onChange={handleChange}
                                isInvalid={
                                  !!touched.lumpsum && !!errors.lumpsum
                                }
                              />
                            </Form.Group>
                          </Col>

                          <Col>
                            <Form.Group>
                              <Form.Label>First Instalment</Form.Label>
                              <Form.Control
                                size="sm"
                                type="number"
                                name="firstInstalment"
                                value={values.firstInstalment}
                                onChange={handleChange}
                                isInvalid={
                                  !!touched.firstInstalment &&
                                  !!errors.firstInstalment
                                }
                              />
                            </Form.Group>
                          </Col>

                          <Col>
                            <Form.Group>
                              <Form.Label>Second Instalment</Form.Label>
                              <Form.Control
                                size="sm"
                                type="number"
                                name="secondInstalment"
                                value={values.secondInstalment}
                                onChange={handleChange}
                                isInvalid={
                                  !!touched.secondInstalment &&
                                  !!errors.secondInstalment
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Modal.Body>

                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                        <Button variant="primary" type="submit">
                          Save Changes
                        </Button>
                      </Modal.Footer>
                    </Form>
                  </>
                )}
              </Formik>
            </Modal>

            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <h6>Fees Discount</h6>

                  {true && (
                    <Button
                      size="sm"
                      onClick={() => navigate("/feesDiscount?action=add")}
                    >
                      ADD
                    </Button>
                  )}
                </div>
              </Card.Header>

              <Card.Body>
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Student</th>
                      <th>Standard</th>
                      <th>Branch</th>
                      <th>Academic Year</th>
                      <th>Token</th>
                      <th>Lump Sum</th>
                      <th>First Instalment</th>
                      <th>Second Instalment</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody key={key}>
                    {response.data?.data.map((item, index) => (
                      <>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item?.student?.name || ""}</td>
                          <td>{item?.standard?.name || ""}</td>
                          <td>{item?.branch?.name || ""}</td>
                          <td>{item?.academicYear?.name || ""}</td>
                          <td>{item?.token}</td>
                          <td>{item?.lumpsum}</td>
                          <td>{item?.firstInstalment}</td>
                          <td>{item?.secondInstalment}</td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                gap: "1rem",
                              }}
                            >
                              <Button
                                size="sm"
                                onClick={() =>
                                  handleShow({
                                    firstInstalment:
                                      item?.firstInstalment || "0",
                                    lumpsum: item?.lumpsum || "0",
                                    secondInstalment:
                                      item?.secondInstalment || "0",
                                    token: item?.token || "0",
                                  })
                                }
                              >
                                UPDATE
                              </Button>
                              <Button
                                size="sm"
                                variant="danger"
                                onClick={() => {
                                  Swal.fire({
                                    title: "Are you sure?",
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      handleDelete(item._id);
                                    }
                                  });
                                }}
                              >
                                DELETE
                              </Button>
                            </div>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Add = () => {
  const navigate = useNavigate();

  const { get: getBranch, response: getBranchResponse } =
    useFetch<GetAllDesignationAPIRoot>("/branch");
  const { get: getAcademicYear, response: academicYearResponse } =
    useFetch<GetAllDesignationAPIRoot>("/educationYear");
  const { post: getStudentByBranch, response: getStudentResponse } =
    useFetch<GetAllStudentRoot>("/student");
  const { post: getFeesMaster, response: getFeesMasterResponse } =
    useFetch<FeesMasterRoot>("/feesMaster");
  const { post } = useFetch<AddDesignationRoot>("/feesDiscountMaster");

  const [isFetching, setIsFetching] = useState(false);
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [getBranch]);

  const fetchData = async () => {
    try {
      setIsFetching(true);

      await getBranch();
      await getAcademicYear();

      setKey(Math.random());

      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
    }
  };

  const handleSubmit = async (values: {
    academicYear: string;
    branch: string;
    firstInstalment: string;
    isActive: boolean;
    lumpsum: string;
    secondInstalment: string;
    standard: string;
    student: string;
    token: string;
  }) => {
    try {
      setIsFetching(true);
      const res = await post(values);
      if (res.success) {
        navigate(-1);
      } else {
        Swal.fire({
          title: "Error",
          text: res.message || "Something went wrong on server.",
          icon: "error",
        });
      }

      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);

      console.log(err);
    }
  };

  if (isFetching) {
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AppProvider>
        <Container
          fluid
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Card>
            <Card.Body>
              <Spinner />
            </Card.Body>
          </Card>
        </Container>
      </AppProvider>
    </div>;
  }

  return (
    <>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Add Fees Discount</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    branch: Yup.string().required(),
                    student: Yup.string().required(),
                    academicYear: Yup.string().required(),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={{
                    branch: "",
                    student: "",
                    standard: "",
                    academicYear: "",
                    token: "0",
                    lumpsum: "0",
                    firstInstalment: "0",
                    secondInstalment: "0",
                    isActive: true,
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                    setFieldValue,
                  }) => (
                    <Form onSubmit={handleSubmit} onChange={handleChange}>
                      <GetStudentByBranch
                        branchId={values.branch}
                        getStudentByBranch={getStudentByBranch}
                        setFieldValue={setFieldValue}
                        name={"student"}
                      />

                      <SetStandard
                        key={values.student}
                        setFieldValue={setFieldValue}
                        standard={values.standard}
                        student={values.student}
                        studentData={getStudentResponse.data?.data || []}
                      />

                      <GetFees
                        key={
                          values.branch + values.student + values.academicYear
                        }
                        academicYear={values.academicYear}
                        getFeesMaster={getFeesMaster}
                        standard={
                          getStudentResponse.data?.data?.find(
                            (item) => item._id === values.student
                          )?.standard._id || ""
                        }
                        setFieldValue={setFieldValue}
                      />

                      <Table>
                        <tbody>
                          <tr>
                            <th>Branch</th>
                            <th>
                              <Form.Select
                                size="sm"
                                name="branch"
                                value={values.branch}
                                isInvalid={!!touched.branch && !!errors.branch}
                              >
                                <option value={""}>select branch</option>

                                {getBranchResponse &&
                                  getBranchResponse.data &&
                                  getBranchResponse.data?.data &&
                                  getBranchResponse.data?.data.map((item) => (
                                    <option value={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                              </Form.Select>
                            </th>
                          </tr>

                          <tr>
                            <th>Student</th>
                            <th>
                              <Form.Select
                                size="sm"
                                name="student"
                                value={values.student}
                                isInvalid={
                                  !!touched.student && !!errors.student
                                }
                              >
                                <option value={""}>select student</option>

                                {getStudentResponse?.data?.data?.map((item) => (
                                  <option value={item._id}>{item.name}</option>
                                ))}
                              </Form.Select>
                            </th>
                          </tr>

                          <tr>
                            <th>Academic Year</th>
                            <th>
                              <Form.Select
                                value={values.academicYear}
                                size="sm"
                                name="academicYear"
                                isInvalid={
                                  !!touched.academicYear &&
                                  !!errors.academicYear
                                }
                              >
                                <option value={""}>select academic year</option>
                                {academicYearResponse?.data?.data.map(
                                  (item) => (
                                    <option value={item._id}>
                                      {item.name}
                                    </option>
                                  )
                                )}
                              </Form.Select>
                            </th>
                          </tr>

                          <tr>
                            <th>Token</th>
                            <th>
                              <Form.Control
                                size="sm"
                                type="number"
                                name="token"
                                value={values.token}
                                onChange={handleChange}
                                isInvalid={!!touched.token && !!errors.token}
                              />
                            </th>
                          </tr>

                          <tr>
                            <th>Lump Sum</th>
                            <th>
                              <Form.Control
                                size="sm"
                                type="number"
                                name="lumpsum"
                                value={values.lumpsum}
                                onChange={handleChange}
                                isInvalid={
                                  !!touched.lumpsum && !!errors.lumpsum
                                }
                              />
                            </th>
                          </tr>

                          <tr>
                            <th>First Instalment</th>
                            <th>
                              <Form.Control
                                size="sm"
                                type="number"
                                name="firstInstalment"
                                value={values.firstInstalment}
                                onChange={handleChange}
                                isInvalid={
                                  !!touched.firstInstalment &&
                                  !!errors.firstInstalment
                                }
                              />
                            </th>
                          </tr>

                          <tr>
                            <th>Second Instalment</th>
                            <th>
                              <Form.Control
                                size="sm"
                                type="number"
                                name="secondInstalment"
                                value={values.secondInstalment}
                                onChange={handleChange}
                                isInvalid={
                                  !!touched.secondInstalment &&
                                  !!errors.secondInstalment
                                }
                              />
                            </th>
                          </tr>

                          <tr>
                            <th>Submit</th>
                            <th>
                              <Button type="submit" size="sm">
                                SUBMIT
                              </Button>
                            </th>
                          </tr>
                        </tbody>
                      </Table>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </>
  );
};

export interface FeesMasterRoot {
  data: FeesMasterData;
  message: string;
  success: boolean;
}

export interface FeesMasterData {
  __v: number;
  _id: string;
  academicYear: string;
  createdAt: string;
  firstInstalment: string;
  isActive: boolean;
  isDeleted: boolean;
  lumpsum: string;
  secondInstalment: string;
  standard: string;
  token: string;
  updatedAt: string;
}

const GetFees: React.FC<{
  standard: string;
  academicYear: string;
  getFeesMaster: FetchData<FeesMasterRoot>;
  setFieldValue: (name: string, value: string | number) => void;
}> = ({ academicYear, standard, getFeesMaster, setFieldValue }) => {
  useEffect(() => {
    (async () => {
      setFieldValue("token", 0);
      setFieldValue("lumpsum", 0);
      setFieldValue("firstInstalment", 0);
      setFieldValue("secondInstalment", 0);

      if (academicYear && standard) {
        await getFeesMaster("/student", {
          standard: standard,
          academicYear: academicYear,
        }).then((res) => {
          if (res.success) {
            setFieldValue("token", res?.data?.token || 0);
            setFieldValue("lumpsum", res?.data?.lumpsum || 0);
            setFieldValue("firstInstalment", res?.data?.firstInstalment || 0);
            setFieldValue("secondInstalment", res?.data?.secondInstalment || 0);
          }
        });
      }
    })();
  }, [standard, academicYear]);

  return <></>;
};

const SetStandard: React.FC<{
  setFieldValue: (name: string, value: string | number) => void;
  standard: string;
  student: string;
  studentData: GetAllStudentDaum[];
}> = ({ setFieldValue, standard, student, studentData }) => {
  useEffect(() => {
    setFieldValue("standard", "");

    let temp = studentData.find((item) => item._id === student);

    setFieldValue("standard", temp?.standard?._id || "");
  }, [student]);

  return <></>;
};

const GetStudentByBranch: React.FC<{
  branchId: string;
  getStudentByBranch: FetchData<GetAllStudentRoot>;
  setFieldValue: (name: string, value: string | number) => void;
  name: string;
}> = ({ branchId, getStudentByBranch, name, setFieldValue }) => {
  useEffect(() => {
    (async () => {
      await getStudentByBranch(`/byBranch`, {
        branchId: branchId,
      });

      setFieldValue("student", "");
      setFieldValue("standard", "");

      setFieldValue("token", 0);
      setFieldValue("lumpsum", 0);
      setFieldValue("firstInstalment", 0);
      setFieldValue("secondInstalment", 0);
    })();
  }, [branchId]);

  return <></>;
};

const Update = () => {
  const navigate = useNavigate();
  const { get, post } = useFetch<GetFeesDiscountMasterByIdRoot>(
    "/feesDiscountMaster"
  );
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const [initValues, setInitValues] = useState({
    discount: 0,
    student: "",
    isActive: false,
  });
  const [key, setKey] = useState(Math.random());
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    (async () => {
      parsed.id && (await fetchData());
    })();
  }, [parsed.id, get]);

  const fetchData = async () => {
    try {
      setIsFetching(true);

      const res = await get(`${parsed.id}`);

      if (res.success) {
        setInitValues({
          isActive: res.data.isActive,
          student: res.data?.student?.name,
          discount: res.data?.discount,
        });

        setKey(Math.random());
      }

      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);

      console.log(err);
    }
  };

  const handleSubmit = async (values: {
    student: String;
    standard: String;
    branch: String;
    year: String;
    fees: number;
    discount: number;
    total: number;
    isActive: Boolean;
  }) => {
    await post(`${parsed.id}`, values)
      .then(async (res) => {
        if (res.success) {
          await get()
            .then((res) => res.success && navigate(-1))
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Update Fees</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    student: Yup.string().required(),
                    standard: Yup.string().required(),
                    branch: Yup.string().required(),
                    year: Yup.string().required(),
                    fees: Yup.number().min(0).required(),
                    discount: Yup.number().min(0).max(100).required(),
                    total: Yup.number().min(0).required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={{
                    student: "",
                    standard: "",
                    branch: "",
                    year: "",
                    fees: 0,
                    discount: 0,
                    total: 0,
                    isActive: true,
                  }}
                  enableReinitialize
                  key={key}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik02"
                          className="mb-3"
                        >
                          <Form.Label>
                            Student <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            disabled
                            type="text"
                            name="student"
                            value={values.student}
                            onChange={handleChange}
                            isValid={touched.student && !errors.student}
                            isInvalid={!!errors.student}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.student}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Discount (%) <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="discount"
                            value={values.discount}
                            onChange={handleChange}
                            isValid={touched.discount && !errors.discount}
                            isInvalid={!!errors.discount}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.discount}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const View = () => {
  return <div></div>;
};

export default FeesDiscountMaster;
