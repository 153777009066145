import { Formik } from "formik";
import { useEffect, useState } from "react";
import {
  Card,
  Container,
  Row,
  Spinner,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import useFetch from "use-http";
import AppProvider from "../../components/AppProvider";
import CustomLoader from "../../components/CustomLoader";
import { PaymentDataDaum, PaymentDataRoot } from "../../utils/types";

const PaymentReport = () => {
  // const [key, setKey] = useState({
  //   android: Math.random(),
  //   ios: Math.random(),
  //   offline:Math.random()
  // });
  return (
    <>
      <AppProvider>
        <Container fluid className="mt-3">
          <Tabs
            defaultActiveKey="android"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="android" title="Android">
              <AndroidPaymentReport />
            </Tab>
            <Tab eventKey="ios" title="IOS">
              <IosPaymentReport />
            </Tab>
            <Tab eventKey="offline" title="Offline">
              <OfflinePaymentReport />
            </Tab>
          </Tabs>
        </Container>
      </AppProvider>
    </>
  );
};

export interface Root {
  success: boolean;
  message: string;
  data: Daum[];
}

export interface Daum {
  _id: string;
  student: Student;
  branch: Branch;
  standard: Standard;
  academicYear: AcademicYear;
  isToken: boolean;
  isFirstInstalment: boolean;
  isSecondInstalment: boolean;
  isLumpSum: boolean;
  amount: string;
  isOffline: boolean;
  isAndroid: boolean;
  isIos: boolean;
  transactionDate: string;
  paymentConfirmation: boolean;
  remark: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface Student {
  _id: string;
  name: string;
  username: string;
  contact: string;
  fatherContact: string;
  motherContact: string;
  standard: string;
  branch: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  academicYear: string;
  address: string;
  batch: string;
  board: string;
  dateOfBirth: string;
  email: string;
  gender: string;
  photo: string;
  school: string;
}

export interface Branch {
  _id: string;
  name: string;
  latitude: string;
  longitude: string;
  radius: string;
  address: string;
  abbreviation: string;
  isFirstHalf: boolean;
  isSecondHalf: boolean;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  accessCode: string;
  merchantId: string;
  password: string;
  username: string;
  workingKey: string;
}

export interface Standard {
  _id: string;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface AcademicYear {
  _id: string;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

const OfflinePaymentReport = () => {
  const [data, setData] = useState<Daum[]>([]);
  const { get, loading, response } = useFetch<Root>("/payment");

  useEffect(() => {
    (async () => await fetchData())();
  }, []);

  const fetchData = async () => {
    try {
      const data = await get("/offline");
      if (data.success) setData(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  if (loading) {
    return (
      <>
        <CustomLoader />
      </>
    );
  }

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Student</th>
            <th>Parent</th>
            <th>Standard</th>
            <th>Branch</th>
            <th>Academic Year</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <>
              <tr>
                <td>{index + 1}</td>
                <td>{item.amount}</td>
                <td>{}</td>
                <td>{item?.student?.name || ""}</td>
                <td>{}</td>
                <td>{item?.standard?.name || ""}</td>
                <td>{item?.branch?.name || ""}</td>
                <td>{item?.academicYear?.name || ""}</td>
              </tr>
            </>
          ))}
        </tbody>
      </Table>
    </>
  );
};

const AndroidPaymentReport = () => {
  const [data, setData] = useState<PaymentDataDaum[]>([]);
  const { get, loading, response } = useFetch<PaymentDataRoot>("/payment");

  useEffect(() => {
    (async () => await fetchData())();
  }, []);

  const fetchData = async () => {
    try {
      const data = await get("/android");
      if (data.success) setData(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  if (loading) {
    return (
      <>
        <Spinner />
      </>
    );
  }

  return (
    <>
      <Card className="mt-3">
        <Card.Header>
          <Row>
            <h6>Android Payment Report</h6>

            <Formik
              initialValues={{
                fromDate: "",
                toDate: "",
              }}
              onSubmit={() => console.log("")}
            >
              {() => <></>}
            </Formik>
          </Row>
        </Card.Header>
        <Card.Body>
          <Table responsive>
            <thead>
              <tr>
                <th>Order Id</th>
                <th>Tracking Id</th>
                <th>Payment Status</th>
                <th>Amount</th>
                <th>Transaction Date</th>
                <th>Parent</th>
                <th>Student</th>
                <th>Standard</th>
                <th>Branch</th>
                <th>Year</th>
                {/* <th>Refresh</th> */}
              </tr>
            </thead>
            <tbody>
              {data.map((x) => (
                <tr>
                  <td>{x.orderId}</td>
                  <td>{x.trackingId}</td>
                  <td>{x.status}</td>
                  <td>{x.amount}</td>
                  <td>{x.transactionDate}</td>
                  <td>{x.parent.name}</td>
                  <td>{x.student.name}</td>
                  <td>{x.standard?.name}</td>
                  <td>{x.branch?.name}</td>
                  <td>{x.academicYear?.name}</td>
                  {/* <td>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => checkStatus(x.enc_val)}
                  >
                    Status
                  </Button>
                </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

const IosPaymentReport = () => {
  const [data, setData] = useState<PaymentDataDaum[]>([]);
  const { get, loading, response } = useFetch<PaymentDataRoot>("/payment");
  const { post } = useFetch<Root>("/ios");

  useEffect(() => {
    (async () => await fetchData())();
  }, []);

  const fetchData = async () => {
    try {
      const data = await get("/ios");
      if (data.success) setData(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const checkStatus = async (encResp: string) => {
    try {
      const res = await post("/status", {
        encResp: encResp,
      });

      await fetchData();
    } catch (err) {}
  };

  if (loading) {
    return (
      <>
        <Spinner />
      </>
    );
  }

  return (
    <>
      <Card className="mt-3">
        <Card.Header>
          <Row>
            <h6>IOS Payment Report</h6>

            <Formik
              initialValues={{
                fromDate: "",
                toDate: "",
              }}
              onSubmit={() => console.log("")}
            >
              {() => <></>}
            </Formik>
          </Row>
        </Card.Header>
        <Card.Body>
          <Table responsive>
            <thead>
              <tr>
                <th>Order Id</th>
                <th>Tracking Id</th>
                <th>Payment Status</th>
                <th>Amount</th>
                <th>Transaction Date</th>
                <th>Parent</th>
                <th>Student</th>
                <th>Standard</th>
                <th>Branch</th>
                <th>Year</th>
                {/* <th>Refresh</th> */}
              </tr>
            </thead>
            <tbody>
              {data.map((x) => (
                <tr>
                  <td>{x.orderId}</td>
                  <td>{x.trackingId}</td>
                  <td>{x.status}</td>
                  <td>{x.amount}</td>
                  <td>{x.transactionDate}</td>
                  <td>{x.parent.name}</td>
                  <td>{x.student.name}</td>
                  <td>{x.standard?.name}</td>
                  <td>{x.branch?.name}</td>
                  <td>{x.academicYear?.name}</td>
                  {/* <td>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => checkStatus(x.enc_val)}
                    >
                      Status
                    </Button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default PaymentReport;
