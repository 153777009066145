import { Formik } from "formik";
import _ from "lodash";
import { asyncMap } from "modern-async";
import { ChangeEvent, useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import useFetch from "use-http";
import { read, utils } from "xlsx";
import * as Yup from "yup";
import AppProvider from "../../components/AppProvider";
import CustomLoader from "../../components/CustomLoader";
import { GetAllBranchRoot } from "../../utils/types";

interface excelInputType {
  NAME: string | null;
  USERNAME: string | null;
  CONTACT: string | null;
  FATHER: string | null;
  MOTHER: string | null;
}

const NAME = 0;
const USERNAME = 1;
const CONTACT = 2;
const FATHER = 3;
const MOTHER = 4;

const UpdateBranch = () => {
  const { get, response } = useFetch<GetAllBranchRoot>("/branch");
  const { post } = useFetch("/student");
  const [isFetching, setIsFetching] = useState(false);
  const [key, setKey] = useState(Math.random());
  const [excelData, setExeclData] = useState<excelInputType[]>();

  useEffect(() => {
    (async () => await fetchData())();
  }, []);

  const fetchData = async () => {
    try {
      setIsFetching(true);
      await get();
      setKey(Math.random());
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      console.log(err);
    }
  };

  const handleFileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const reader = new FileReader();

      reader.onload = (e) => {
        //@ts-ignore
        const data = e.target.result;
        const workbook = read(data, { type: "array" });
        var firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        var result = utils.sheet_to_json(firstSheet, { header: 1 });

        const arr: excelInputType[] = [];

        result.map((x) => {
          Array.isArray(x) &&
            arr.push({
              NAME: x[NAME],
              USERNAME: x[USERNAME],
              CONTACT: x[CONTACT],
              FATHER: x[FATHER],
              MOTHER: x[MOTHER],
            });
        });

        const filterdData = arr.filter(
          (x) =>
            !(
              _.isEmpty(x.NAME) &&
              _.isEmpty(x.USERNAME) &&
              _.isEmpty(x.CONTACT) &&
              _.isEmpty(x.FATHER) &&
              _.isEmpty(x.MOTHER)
            )
        );

        setExeclData(filterdData.slice(1));
      };
      // @ts-ignore
      reader.readAsArrayBuffer(e.target.files[0]);

      toast.success("file readed successfully");
    } catch (err) {
      toast.error("Something went wrong while file upload");
    }
  };

  const handleSubmit = async (values: { branch: string }) => {
    if (excelData && excelData?.length !== 0) {
      try {
        setIsFetching(true);

        await asyncMap(excelData, async (x) => {
          await post("/updateBranch", {
            username: x.USERNAME,
            branch: values.branch,
          });
        });

        toast.success("BRANCH UPDATED");

        setIsFetching(false);
      } catch (err) {
        setIsFetching(false);
        console.log(err);
      }
    } else {
      toast.error("NO DATA AVAILABLE OR SELECT EXCEL");
      return;
    }
  };

  if (isFetching) {
    return (
      <AppProvider>
        <CustomLoader />
      </AppProvider>
    );
  }

  return (
    <AppProvider>
      <Container fluid key={key}>
        <Card className="mt-3">
          <Card.Header>
            <h5>UPDATE BRANCH</h5>
          </Card.Header>

          <Card.Body>
            <Formik
              initialValues={{
                branch: "",
              }}
              validationSchema={Yup.object().shape({
                branch: Yup.string().required(),
              })}
              onSubmit={handleSubmit}
              key={key}
            >
              {({
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => {
                return (
                  <>
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                          <Form.Group className="mb-3">
                            <Form.Label>Branch</Form.Label>
                            <Form.Select
                              size="sm"
                              name="branch"
                              isInvalid={!!touched.branch && !!errors.branch}
                            >
                              <option value={""}>select branch</option>
                              {response.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                          <Form.Group className="mb-3">
                            <Form.Group controlId="formFile" className="mb-3">
                              <Form.Label>Select excel file</Form.Label>
                              <Form.Control
                                type="file"
                                size="sm"
                                accept=".xlsx, .xls, .csv"
                                onChange={handleFileSelect}
                              />
                            </Form.Group>
                          </Form.Group>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                          <Button type="submit">Submit</Button>
                        </Col>
                      </Row>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </Card.Body>
        </Card>
      </Container>
    </AppProvider>
  );
};

export default UpdateBranch;
